import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import {
  fetchClients,
  fetchDeteteUser,
  fetchSignSave,
  fetchConfirmCode,
  fetchChangeStatusClient,
  fetchChangeAdmin,
  fetchBonusSpend,
  fetchCommentUser,
  fetchUserPaswordReset,
  fetchFindUsers,
} from "../http";
import { useState, useContext } from "react";
import { DeleteUserDialog } from "../components/DeleteUserDialog";
import { ErrorDialog } from "../components/ErrorDialog";
import { AuthContext } from "../App";
import pageStyle from "./Page.module.css";
import { UserCard } from "../components/UserCard";
import { clsx } from "../helpers";
import { userLevels, debounce } from "../helpers";

export const Clients = () => {
  const [users, setUsers] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const { user: currentUser } = useContext(AuthContext);

  useEffect(() => {
    fetchClients().then((res) => setUsers(res.users));
  }, []);

  const handleDelete = async (userId, userRole) => {
    const result = await fetchDeteteUser({ id: userId, role: userRole });
    if (result.message) {
      setErrorMessage(result.message);
      handleClose();
      setShowErrorDialog(true);
      return;
    }

    if (result.result) {
      setUsers(users.filter((item) => item.id !== userId));
      handleClose();
      handleCloseUserInfo();
    }
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const signSave = async (userId) => {
    const result = await fetchSignSave({ id: userId });

    if (result.success) {
      setUsers(
        users.map((item) => {
          if (item.id === userId) item.isSigned = true;
          return item;
        })
      );
    }
  };

  const confirmUser = async (userId, phone) => {
    const result = await fetchConfirmCode({
      userId,
      phone,
      role: currentUser.role,
    });

    if (result.success) {
      setUsers(
        users.map((item) => {
          if (item.id === userId) item.smsCode = "success";
          return item;
        })
      );
    }
  };

  const showUserInfo = (user) => {
    setSelectedUser(user);
    setShowCard(true);
  };

  const handleCloseUserInfo = () => {
    setSelectedUser({});
    setShowCard(false);
  };

  const changeUserLvl = async (userId, newType) => {
    setIsLoading(true);
    const result = await fetchChangeStatusClient({
      id: userId,
      type: newType,
    });
    if (result.success) {
      setUsers(
        users.map((item) => {
          if (item.id === userId) item.type = newType;
          return item;
        })
      );
    }
    setIsLoading(false);
  };

  const openDeleteDialog = () => {
    setShowDialog(true);
  };

  const changeCommentUser = async ({ newComment, id, uid }) => {
    const result = await fetchCommentUser({ id, uid, comment: newComment });

    if (result.success) {
      setUsers(
        users.map((item) => {
          if (item.id === id) item.comment = newComment;
          return item;
        })
      );
    }
  };

  const switchAdmin = async (userId, role) => {
    setIsLoading(true);
    const result = await fetchChangeAdmin({ id: userId, role });

    if (result.success) {
      setUsers(
        users.map((item) => {
          if (item.id === userId) item.role = role;
          return item;
        })
      );
    }
    setIsLoading(false);
  };

  const handleSpendFreeSets = async (userId, toSpendSets) => {
    const result = await fetchBonusSpend({ userId, toSpendSets });

    if (result.success) {
      setUsers(
        users.map((item) => {
          if (item.id === userId) {
            item.toSpendSets -= toSpendSets;
            item.countSets -= toSpendSets;
            item.spendSets += toSpendSets;
          }
          return item;
        })
      );
    }
  };

  const resetPassword = async (userId) => {
    setIsLoading(true);
    await fetchUserPaswordReset({ userId });
    setIsLoading(false);
  };

  const handleChangeText = (event) => {
    const searchString = (event.target.value = event.target.value.trim());

    if (searchString)
      fetchFindUsers({ searchString }).then((result) => setUsers(result.users));
    else {
      fetchClients().then((res) => setUsers(res.users));
    }
  };

  return (
    <div className={pageStyle["page-content"]}>
      <TextField
        fullWidth
        onChange={debounce(handleChangeText, 500)}
        placeholder="Введите имя или фамилию"
        sx={{ background: "#fff", marginBottom: "20px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className={pageStyle["content-wrapper"]}>
        <List sx={{ width: "100%", bgcolor: "transparent" }}>
          {users &&
            users.map((user, index) => (
              <ListItem
                className={clsx(
                  pageStyle["user-list"],
                  pageStyle["user-list--pointer"]
                )}
                alignItems="flex-start"
                key={user.id.toString() + index}
                onClick={() => {
                  showUserInfo(user);
                }}
              >
                <ListItemAvatar>
                  <Avatar>{index + 1}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.name} ${user.secondName}`}
                  secondary={
                    <>
                      {user.phone.slice(0, user.phone[0] === "+" ? 5 : 4)}
                      ...
                      {user.phone.slice(-3)} {user.role}
                    </>
                  }
                  secondaryTypographyProps={{
                    sx: { display: "inline" },
                    component: "span",
                    variant: "body2",
                    color: "text.primary",
                  }}
                />
                {user.smsCode !== undefined && user.smsCode !== "success" && (
                  <Chip
                    sx={{
                      marginLeft: "10px",
                      alignSelf: "center",
                    }}
                    variant="filled"
                    label="Не подтвержден"
                    color="success"
                  />
                )}
                {!user.isSigned ? (
                  <Chip
                    sx={{
                      marginLeft: "10px",
                      alignSelf: "center",
                    }}
                    variant="filled"
                    label="Техника безопасности!"
                    color="warning"
                  />
                ) : (
                  userLevels
                    .filter((userLvl) => userLvl.type === user.type)
                    .map((userLvl) => (
                      <Chip
                        key={userLvl.type}
                        sx={{
                          marginLeft: "10px",
                          alignSelf: "center",
                        }}
                        variant="filled"
                        label={userLvl.name}
                        color={userLvl.color}
                      />
                    ))
                )}
              </ListItem>
            ))}
        </List>
        {showDialog && (
          <DeleteUserDialog
            showDialog={showDialog}
            userRole={selectedUser.role}
            userName={`${selectedUser.name} ${selectedUser.secondName}`}
            handleClose={handleClose}
            handleDeleteClick={() =>
              handleDelete(selectedUser.id, selectedUser.role)
            }
          />
        )}
        {showCard && (
          <UserCard
            showDialog={showCard}
            user={selectedUser}
            handleClose={handleCloseUserInfo}
            signSave={signSave}
            confirmUser={confirmUser}
            currentUser={currentUser}
            changeUserLvl={changeUserLvl}
            openDeleteDialog={openDeleteDialog}
            changeCommentUser={changeCommentUser}
            switchAdmin={switchAdmin}
            handleSpendFreeSets={handleSpendFreeSets}
            resetPassword={resetPassword}
            isLoading={isLoading}
          />
        )}
        {showErrorDialog && (
          <ErrorDialog
            showDialog={showErrorDialog}
            handleCloseDialog={() => {
              setShowErrorDialog(false);
            }}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </div>
  );
};
