import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchLogs } from "../http";
import pageStyle from "./Page.module.css";
import { clsx } from "../helpers";

export const DataInfo = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchLogs().then((result) => {
      setLogs(result.logs);
    });
  }, []);

  return (
    <div className={clsx(pageStyle["page-content"], pageStyle["center"])}>
      <div className={pageStyle["content-wrapper"]}>
        {logs.map((log) => (
          <div style={{ display: "flex", gap: 8, padding: 10 }}>
            <Typography style={{ width: 160 }}>{log.action}</Typography>
            <Typography style={{ width: "calc(100% - 360px)", flexGrow: 0 }}>
              {JSON.stringify(log.description).replace(/,/g, ", ")}
            </Typography>
            <Typography style={{ width: 200 }}>{log.attach || ""}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
