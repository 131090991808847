import pageStyle from "./Page.module.css";
import { clsx } from "../helpers";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { fetchStatusBonus, fetchChangeStatusBonus } from "../http";
import Typography from "@mui/material/Typography";

export const BonusControl = () => {
  const [bonuses, setBonuses] = useState([]);

  useEffect(() => {
    fetchStatusBonus().then(({ params }) => {
      params && params.length && setBonuses(params);
    });
  }, []);

  const handleSwitchBonus = async (bonusId, bonusStatus) => {
    const result = await fetchChangeStatusBonus({
      id: bonusId,
      status: bonusStatus,
    });

    if (result.success) {
      setBonuses(
        bonuses.map((item) => {
          if (item.id === bonusId) item.status = bonusStatus;
          return item;
        })
      );
    }
  };

  return (
    <div className={clsx(pageStyle["page-content"], pageStyle["center"])}>
      <div className={pageStyle["content-wrapper"]}>
        {bonuses && bonuses.length ? (
          <List sx={{ width: "100%", bgcolor: "transparent" }}>
            {bonuses &&
              bonuses.map((bonus, index) => (
                <ListItem
                  className={pageStyle["user-list"]}
                  alignItems='flex-start'
                  key={bonus.id.toString() + index}
                  secondaryAction={
                    <Chip
                      color={bonus.status ? "error" : "success"}
                      label={bonus.status ? "Отключить" : "Включить"}
                      onClick={() => handleSwitchBonus(bonus.id, !bonus.status)}
                    />
                  }
                >
                  <ListItemAvatar>
                    <Avatar>{index + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ primary: pageStyle.short }}
                    primary={bonus.parameter}
                    secondary={bonus.status ? "Включен" : "Отключен"}
                    secondaryTypographyProps={{
                      component: "span",
                      color: bonus.status ? "green" : "error",
                    }}
                  />
                </ListItem>
              ))}
          </List>
        ) : (
          <Typography>Нет бонусных программ</Typography>
        )}
      </div>
    </div>
  );
};
