import { RidesInfo } from "./RidesInfo";
import { useState, useEffect } from "react";
import login from "./Login.module.css";
import moment from "moment";
import { AddFieldsForm } from "../components/AddFieldsForm";
import Button from "@mui/material/Button";
import { regExpDic } from "../helpers";

export const RideGuests = () => {
  const [guest, setGuest] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [birthday, setBirthday] = useState(moment());
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  useEffect(() => {
    if (!guest.phone) {
      setPhone("");
      setName("");
      setSecondName("");
      setEmail("");
      setBirthday(moment());
      setErrorMessage("");
      setFetchError(false);
    }
  }, [guest]);

  const handleRegistration = (e) => {
    e.preventDefault();
    if (!phone || !name || !secondName) {
      setErrorMessage("Заполните все обязательные поля");
      return;
    }

    if (regExpDic.phone.test(phone)) {
      if (phone.length === 11 && phone.substring(0, 2) !== "89") {
        setIsValidPhone(false);
        return;
      }
      if (phone.length === 12 && phone.substring(0, 3) !== "+79") {
        setIsValidPhone(false);
        return;
      }
    } else return;

    setErrorMessage("");

    setGuest({
      phone,
      name,
      secondName,
      birthday: birthday.format("DD.MM.yyyy"),
    });
  };

  return guest.phone ? (
    <RidesInfo isGuest guest={guest} setGuest={setGuest} />
  ) : (
    <div className={login.registration}>
      <form className={login.form} onSubmit={handleRegistration}>
        <AddFieldsForm
          isGuest
          phone={phone}
          setPhone={setPhone}
          fetchError={fetchError}
          setFetchError={setFetchError}
          errorMessage={errorMessage}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          secondName={secondName}
          setSecondName={setSecondName}
          birthday={birthday}
          setBirthday={setBirthday}
          isValidEmail={isValidEmail}
          setIsValidEmail={setIsValidEmail}
          isValidPhone={isValidPhone}
          setIsValidPhone={setIsValidPhone}
        />
        <Button
          variant='contained'
          type='submit'
          classes={{ root: login.button }}
        >
          Оформить гостя
        </Button>
      </form>
    </div>
  );
};
