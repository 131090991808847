export const clsx = (...args) => {
  return [...args].join(" ");
};

export const regExpDic = {
  email: /.+@.+\..+/i,
  password: /^[0-9a-zA-Z]{4,}$/,
  name: /^[a-zA-Z]{1,}$/,
  secondName: /.{1,}$/,
  phone: /^[0-9+]{11,12}$/,
};

export const userLevels = [
  { type: "STANDART", name: "Стандарт", color: "primary" },
  { type: "OFTEN", name: "Постоянный", color: "secondary" },
  { type: "OUR", name: "Свой", color: "warning" },
];

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
