import "./App.css";
import "./routes";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  publicRoutes,
  authRoutes,
  adminRoutes,
  trainerRoutes,
  clientRoutes,
} from "./routes";
import { Header } from "./components/Header";
import { useEffect, useState, createContext } from "react";
import {
  check,
  fetchAllPrice,
  fetchBonusFree,
  fetchStatusBonus,
} from "./http/index";
import { WarningPasswordDialog } from "./components/WarningPasswordDialog";
import ruLocale from "moment/locale/ru";

export const AuthContext = createContext();

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [priceList, setPriceList] = useState({});
  const [user, setUser] = useState({});
  const [config, setConfig] = useState({});
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  useEffect(() => {
    !!localStorage.getItem("token") &&
      check().then((userInfo) => {
        if (userInfo) {
          userInfo.id && setIsAuth(true);
          setUser(userInfo);
          fetchBonusFree().then(({ freeSets }) => {
            freeSets > 0 && setUser((prevInfo) => ({ ...prevInfo, freeSets }));
          });
        }
      });
  }, []);

  useEffect(() => {
    if (user.defaultPass) {
      setShowPasswordDialog(true);
    }
    if (user.defaultPass !== undefined) {
      delete user.defaultPass;
      setUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (isAuth) {
      fetchAllPrice().then(({ price }) => {
        if (price && price.length) {
          const newPriceList = price.reduce((acc, prices) => {
            acc[prices.category] = prices.types;
            return acc;
          }, {});

          setPriceList(newPriceList);
        }
      });
      fetchStatusBonus().then(({ params }) => {
        params &&
          params.length &&
          setConfig((prevConfig) => ({
            ...prevConfig,
            ...params.reduce((conf, param) => {
              conf[param.parameter] = param;
              return conf;
            }, {}),
          }));
      });
    }
  }, [isAuth]);

  const handleCloseDialog = () => {
    setShowPasswordDialog(false);
  };

  return (
    <>
      <div className='cover'></div>
      <div className='app-wrapper'>
        <AuthContext.Provider
          value={{ isAuth, setIsAuth, user, setUser, priceList, config }}
        >
          <BrowserRouter>
            <Header />
            <main>
              <Routes>
                {publicRoutes.map((item) => (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={item.component}
                    exact
                  />
                ))}
                {clientRoutes.map((item) => (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={item.component}
                    exact
                  />
                ))}
                {isAuth &&
                  authRoutes
                    .filter(
                      (item) => item.access && item.access.includes(user.role)
                    )
                    .map((item) => (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.component}
                        exact
                      />
                    ))}
                {isAuth &&
                  trainerRoutes
                    .filter(
                      (item) => item.access && item.access.includes(user.role)
                    )
                    .map((item) => (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.component}
                        exact
                      />
                    ))}
                {isAuth &&
                  adminRoutes
                    .filter(
                      (item) => item.access && item.access.includes(user.role)
                    )
                    .map((item, index) => (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.component}
                        exact
                      />
                    ))}
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>
              <WarningPasswordDialog
                showDialog={showPasswordDialog}
                handleClose={handleCloseDialog}
                id={user.id}
              />
            </main>
          </BrowserRouter>
        </AuthContext.Provider>
      </div>
    </>
  );
}

export default App;
