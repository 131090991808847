import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import { fetchUserTikets, fetchDeleteTikets } from "../http/index";
import rides from "./Rides.module.css";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import pageStyle from "./Page.module.css";
import skiStyle from "./Ski.module.css";
import { clsx } from "../helpers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div sx={{ p: 3 }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const SkiInfo = () => {
  const { user } = useContext(AuthContext);
  const [type, setType] = useState(0);
  const [userTikets, setUserTikets] = useState({});
  const [displayDate, setDisplayDate] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [choosedDay, setChoosedDay] = useState("");
  const [sets, setSets] = useState([]);
  const [choosedType, setChoosedType] = useState([]);
  const [track, setTrack] = useState("simple");
  const [attach, setAttach] = useState("");
  const isSmallScreen = window.screen.width < 600;

  const [isDisabledButtons, setIsDisabledButtons] = useState(false);

  useEffect(() => {
    fetchUserTikets().then(({ tikets }) => setUserTikets(tikets));
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClickDelete = (setsToDelete) => {
    setSets(setsToDelete);
    setShowDeleteDialog(true);
  };

  const handleUnReservedClick = async () => {
    let simpleSets = [];
    let track2Sets = [];
    let groups = [];
    const trainers = {};
    const allTimes = userTikets[choosedDay];
    if (choosedType.length > 1) {
      choosedType.forEach((currentType) => {
        for (const time in allTimes[currentType]) {
          const { trainer, group } = allTimes[currentType][time];
          if (trainer !== "Без тренера") {
            if (!trainers[trainer]) {
              trainers[trainer] = [time];
            } else trainers[trainer].push(time);
          }
          if (group && !groups.some((item) => item.includes(time)))
            groups.push(group);
          if (currentType === "simple") simpleSets.push(time);
          if (currentType === "track2") track2Sets.push(time);
        }
      });
    } else {
      const currentType = choosedType[0];
      const time = sets[0];
      const isGroup = allTimes[currentType][time].category !== "сет";

      const { trainer, group } = allTimes[currentType][time];
      if (trainer !== "Без тренера") {
        if (!trainers[trainer]) {
          trainers[trainer] = isGroup
            ? allTimes[currentType][time].group
            : [time];
        } else trainers[trainer].push(time);
      }
      if (group && !groups.some((item) => item.includes(time)))
        groups.push(group);
      if (currentType === "simple") {
        if (isGroup) {
          simpleSets = allTimes[currentType][time].group;
        } else simpleSets.push(time);
      }
      if (currentType === "track2") {
        if (isGroup) {
          track2Sets = allTimes[currentType][time].group;
        } else track2Sets.push(time);
      }
    }

    const res = await fetchDeleteTikets({
      date: choosedDay,
      simpleSets,
      track2Sets,
      trainers,
      user: { id: user.id },
      attach,
      groups,
    });

    if (res.result) {
      const { tikets } = await fetchUserTikets();
      setUserTikets(tikets);
      if (!Object.keys(tikets).length) setDisplayDate(true);
      setAttach("");
    }
    handleClose();
  };

  const handleClose = () => {
    setSets([]);
    setShowDeleteDialog(false);
  };

  return (
    <div className={clsx(pageStyle["page-content"], pageStyle["center"])}>
      <div
        className={clsx(
          pageStyle["page-content_500"],
          pageStyle["content-wrapper"]
        )}
        sx={{ minWidth: isSmallScreen ? 310 : 500 }}
      >
        {!Object.keys(userTikets).length && (
          <Typography color="primary" align="center" sx={{ margin: 10 }}>
            У Вас нет забронированных поездок
          </Typography>
        )}

        {!displayDate && (
          <div className={skiStyle["day-control"]}>
            <Chip
              variant={"outlined"}
              color={"primary"}
              label="Назад"
              onClick={(checked) => {
                setDisplayDate(true);
              }}
            />
            <Typography variant="h5">{choosedDay}</Typography>
          </div>
        )}

        {displayDate ? (
          <div className={clsx(rides.content, pageStyle["page-content_500"])}>
            {Object.keys(userTikets)
              .sort((a, b) => {
                return (
                  a.split(".").reverse().join("") -
                  b.split(".").reverse().join("")
                );
              })
              .map((tiket) => (
                <Chip
                  key={tiket}
                  variant={"filled"}
                  color={"primary"}
                  label={tiket}
                  deleteIcon={<DeleteForeverIcon />}
                  onClick={() => {
                    setDisplayDate(false);
                    setChoosedDay(tiket);
                  }}
                  onDelete={() => {
                    setChoosedDay(tiket);
                    setChoosedType(["simple", "track2"]);
                    const simpleTime = userTikets[tiket]["simple"]
                      ? Object.keys(userTikets[tiket]["simple"])
                      : [];
                    const track2Time = userTikets[tiket]["track2"]
                      ? Object.keys(userTikets[tiket]["track2"])
                      : [];
                    handleClickDelete([...simpleTime, ...track2Time]);
                  }}
                />
              ))}
          </div>
        ) : (
          <>
            <Tabs
              value={type}
              onChange={(e, val) => {
                setType(val);
                setTrack(val ? "track2" : "simple");
              }}
              aria-label="Расписание"
              centered
            >
              <Tab label="Стандарт" {...a11yProps(0)} />
              <Tab label="PRO" {...a11yProps(1)} />
            </Tabs>
            <div
              className={clsx(
                rides.time,
                skiStyle["sets-center"],
                pageStyle["page-content_500"]
              )}
            >
              {userTikets[choosedDay] &&
                userTikets[choosedDay][track] &&
                Object.keys(userTikets[choosedDay][track])
                  .sort((a, b) => {
                    if (a.length < 5) a = "0" + a;
                    if (b.length < 5) b = "0" + b;
                    return a.localeCompare(b);
                  })
                  .map((time) => (
                    <div key={time} className={rides.timeString}>
                      <Chip
                        label={time}
                        variant={"filled"}
                        color={"primary"}
                        deleteIcon={<DeleteForeverIcon />}
                        onDelete={() => {
                          setChoosedType([track]);
                          if (
                            userTikets[choosedDay][track][time].category !==
                            "сет"
                          ) {
                            handleClickDelete(
                              userTikets[choosedDay][track][time].group
                            );
                          } else handleClickDelete([time]);
                        }}
                      />
                      <div>
                        <Typography>
                          Тренер: {userTikets[choosedDay][track][time].trainer}
                        </Typography>
                        <Typography>
                          Оборудование:{" "}
                          {userTikets[choosedDay][track][time].choosedEquips}
                        </Typography>
                        <Typography>
                          Категория:{" "}
                          {userTikets[choosedDay][track][time].category}
                        </Typography>
                      </div>
                    </div>
                  ))}
            </div>
          </>
        )}

        {showDeleteDialog && (
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={showDeleteDialog}
            onClose={handleClose}
          >
            <DialogTitle>Подтверждение</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Вы действительно хотите отменить забронированное время{" "}
                {sets.join(", ")} на имя "{user.secondName} {user.name}"{" "}
                {choosedDay}?
              </DialogContentText>
              <DialogContentText>Укажите причину отмены:</DialogContentText>
              <TextField
                multiline
                fullWidth
                defaultValue={attach}
                onChange={(event) => {
                  setAttach(event.target.value.trim());
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={isDisabledButtons}>
                Отменить
              </Button>
              <Button
                onClick={async () => {
                  setIsDisabledButtons(true);
                  await handleUnReservedClick().finally(() =>
                    setIsDisabledButtons(false)
                  );
                }}
                variant="contained"
                disabled={!attach || attach.length < 6 || isDisabledButtons}
              >
                Подтвердить
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};
