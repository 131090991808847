import { Login } from "./pages/Login";
import { Registration } from "./pages/Registration";
import { UserInfo } from "./pages/UserInfo";
import { RidesInfo } from "./pages/RidesInfo";
import { RidesAdmin } from "./pages/RidesAdmin";
import { SkiInfo } from "./pages/SkiInfo";
import { Trainers } from "./pages/Trainers";
import { Clients } from "./pages/Clients";
import { RulesInfo } from "./pages/RulesInfo";
import { PriceInfo } from "./pages/PriceInfo";
import KitesurfingIcon from "@mui/icons-material/Kitesurfing";
import SnowboardingIcon from "@mui/icons-material/Snowboarding";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import InfoIcon from "@mui/icons-material/Info";
import { OurTrainers } from "./pages/OurTrainers";
import { DataInfo } from "./pages/DataInfo";
import { BonusControl } from "./pages/BonusControl";
import { Guests } from "./pages/Guests";
import { RideGuests } from "./pages/RideGuests";

export const publicRoutes = [
  {
    path: "/",
    component: <Login />,
    pageName: "Вход",
  },
  {
    path: "/registration",
    component: <Registration />,
    pageName: "Регистрация",
  },
];

export const authRoutes = [
  {
    path: "/rides",
    link: "",
    component: <RidesInfo />,
    pageName: "Забронировать",
    icon: <SnowboardingIcon fontSize='small' />,
    access: ["USER", "OWNER"],
  },
  {
    path: "/ski/:id",
    link: "/ski/",
    component: <SkiInfo />,
    pageName: "Мои поездки",
    icon: <KitesurfingIcon fontSize='small' />,
    access: ["USER", "OWNER"],
  },
  {
    path: "/user/:id",
    link: "",
    component: <UserInfo />,
    pageName: "Профиль",
    icon: <AccountCircleIcon fontSize='small' />,
    access: ["USER", "TRAINER", "ADMIN", "OWNER"],
  },
  {
    path: "/price",
    link: "",
    component: <PriceInfo />,
    pageName: "Стоимость",
    icon: <RequestQuoteIcon fontSize='small' />,
    access: ["USER", "OWNER"],
  },
];

export const clientRoutes = [
  {
    path: "/rules",
    link: "",
    component: <RulesInfo />,
    pageName: "Правила",
    icon: <WarningAmberIcon fontSize='small' />,
  },
  {
    path: "/ourTrainers",
    link: "",
    component: <OurTrainers />,
    pageName: "Наши тренеры",
    icon: <AccessibilityNewIcon fontSize='small' />,
  },
];

export const trainerRoutes = [
  {
    path: "/view",
    component: <RidesAdmin />,
    pageName: "Расписание",
    icon: <AccessTimeIcon fontSize='small' />,
    access: ["OWNER", "ADMIN", "TRAINER"],
  },
];

export const adminRoutes = [
  {
    path: "/priceall",
    link: "",
    component: <PriceInfo isAllPrice />,
    pageName: "вся стоимость",
    icon: <RequestQuoteIcon fontSize='small' />,
    access: ["ADMIN", "OWNER"],
  },
  {
    path: "/trainers",
    component: <Trainers />,
    pageName: "Тренерская",
    icon: <AccessibilityNewIcon fontSize='small' />,
    access: ["OWNER"],
  },
  {
    path: "/clients",
    component: <Clients />,
    pageName: "Клиенты",
    icon: <Diversity3Icon fontSize='small' />,
    access: ["OWNER", "ADMIN"],
  },
  {
    path: "/guests",
    component: <Guests />,
    pageName: "Гости",
    icon: <Diversity3Icon fontSize='small' />,
    access: ["OWNER", "ADMIN"],
  },
  {
    path: "/ridesguests",
    component: <RideGuests />,
    pageName: "Бронирование гостей",
    icon: <SnowboardingIcon fontSize='small' />,
    access: ["OWNER", "ADMIN"],
  },
  {
    path: "/logs",
    component: <DataInfo />,
    pageName: "Логирование",
    icon: <InfoIcon fontSize='small' />,
    access: ["OWNER"],
  },
  {
    path: "/bonus",
    component: <BonusControl />,
    pageName: "Бонусы",
    icon: <CardGiftcardIcon fontSize='small' />,
    access: ["OWNER"],
  },
];
