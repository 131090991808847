import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useState } from "react";

export const DeleteUserDialog = ({
  showDialog,
  userRole,
  userName,
  handleClose,
  handleDeleteClick,
}) => {
  const getUserRole = (role) => {
    switch (role) {
      case "OWNER":
        return "владельца ";
      case "ADMIN":
        return "администратора ";
      case "TRAINER":
        return "тренера ";
      default:
        return "пользователя ";
    }
  };
  const [isDisabledButtons, setIsDisabledButtons] = useState(false);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>Внимание!</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ margin: "5px 0" }}>
          Вы действительно хотите удалить {getUserRole(userRole)}"{userName}" ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isDisabledButtons}>
          Отменить
        </Button>
        <Button
          onClick={async () => {
            setIsDisabledButtons(true);
            await handleDeleteClick().finaly(() => setIsDisabledButtons(false));
          }}
          disabled={isDisabledButtons}
          variant="contained"
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
