import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useContext, useEffect, useState } from "react";
import login from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { fetchLogin, fetchBonusFree } from "../http/index";
import { AuthContext } from "../App";
import jwt_decode from "jwt-decode";
import pageStyle from "./Page.module.css";

export const Login = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passInput, setPassInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const { isAuth, setIsAuth, setUser, user } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      if (isTrainer(user)) {
        return;
      }
      if (isAdmin(user)) return;
      navigate("/rides", { replace: true });
    }
  }, [isAuth]);

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!emailInput.length) {
      setErrorMessage("Введите email");
      return;
    }
    if (!passInput.length) {
      setErrorMessage("Введите password");
      return;
    }

    const result = await fetchLogin({
      phone: emailInput,
      password: passInput,
    });

    if (result.message) {
      setErrorMessage(result.message);
      setFetchError(true);
      return;
    }

    localStorage.setItem("token", result.token);
    setIsAuth(true);
    const userToken = jwt_decode(result.token);
    setUser(userToken);
    userToken &&
      fetchBonusFree().then(({ freeSets }) => {
        setUser((prevInfo) => ({ ...prevInfo, freeSets }));
      });

    if (isTrainer(userToken)) {
      return;
    }
    navigate("/rides", { replace: true });
  };

  const isTrainer = (user) => {
    if (user.role === "TRAINER") {
      navigate("/view", { replace: true });
      return true;
    }
    return false;
  };

  const isAdmin = (user) => {
    if (user.role === "ADMIN") {
      navigate("/ridesguests", { replace: true });
      return true;
    }
    return false;
  };

  return (
    <div className={login.main}>
      <form onSubmit={handleLogin} className={login.form}>
        <TextField
          label='Телефон'
          name='phone'
          onChange={(event) => {
            setEmailInput(event.currentTarget.value.replace(/\s|\(|\)|-/g, ""));
            fetchError && setFetchError(false);
          }}
          error={(!emailInput.length && !!errorMessage.length) || fetchError}
        />
        <TextField
          label='Пароль'
          name='password'
          type='password'
          onChange={(event) => {
            setPassInput(event.currentTarget.value);
            fetchError && setFetchError(false);
          }}
          error={(!passInput.length && !!errorMessage.length) || fetchError}
        />
        <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
          {errorMessage}
        </Typography>
        <Button
          variant='contained'
          classes={{ root: login.button }}
          type='submit'
        >
          Войти
        </Button>
        <Button
          variant='text'
          classes={{ root: login.button }}
          onClick={() => {
            navigate("/registration", { replace: true });
          }}
        >
          Регистрация
        </Button>
      </form>
    </div>
  );
};
