import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState, useContext } from "react";
import { AuthContext } from "../App";
import { useNavigate, useLocation } from "react-router-dom";
import header from "./Header.module.css";
import {
  authRoutes,
  adminRoutes,
  trainerRoutes,
  clientRoutes,
} from "../routes";

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isAuth, setIsAuth, setUser, user } = useContext(AuthContext);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  let location = useLocation();

  const getPageName = () => {
    if (location.pathname.includes("/view")) return "Расписание";
    if (location.pathname.includes("/ridesguests"))
      return "Бронирование гостей";
    if (location.pathname.includes("/rides")) return "Забронировать";
  };
  const [pageTitle, setPageTitle] = useState(getPageName());

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={header.fullwidth}>
      <div className={header.bar}>
        <div className={header.bar__title}>
          <Typography classes={{ root: header.logo }}>TANTRUM</Typography>
        </div>
        {isAuth && (
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon color='primary' />
          </IconButton>
        )}
      </div>

      <Typography variant='h2' classes={{ root: header.title }}>
        {pageTitle}
      </Typography>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {authRoutes
          .filter(
            (item) =>
              !item.path.includes("/user") &&
              item.access &&
              item.access.includes(user.role)
          )
          .map((item) => (
            <MenuItem
              key={item.pageName}
              onClick={() => {
                setPageTitle(item.pageName);
                navigate(
                  item.path.includes(":id") ? item.link + user.id : item.path
                );
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.pageName}
            </MenuItem>
          ))}
        {user.role !== "TRAINER" && <Divider />}
        {clientRoutes.map((item) => (
          <MenuItem
            key={item.pageName}
            onClick={() => {
              setPageTitle(item.pageName);
              navigate(
                item.path.includes(":id") ? item.link + user.id : item.path
              );
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.pageName}
          </MenuItem>
        ))}
        {user.role !== "USER" && <Divider />}
        {user.role !== "USER" &&
          trainerRoutes.map((item) => (
            <MenuItem
              key={item.pageName}
              onClick={() => {
                setPageTitle(item.pageName);
                navigate(item.path);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.pageName}
            </MenuItem>
          ))}
        {(user.role === "OWNER" || user.role === "ADMIN") &&
          adminRoutes
            .filter((menu) => menu.access && menu.access.includes(user.role))
            .map((item) => (
              <MenuItem
                key={item.pageName}
                onClick={() => {
                  setPageTitle(item.pageName);
                  navigate(item.path);
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.pageName}
              </MenuItem>
            ))}
        <Divider />
        <MenuItem
          onClick={() => {
            setPageTitle("Профиль");
            navigate(`/user/${user.id}`);
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize='small' />
          </ListItemIcon>
          Профиль
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.removeItem("token");
            setIsAuth(false);
            setUser({});
            setPageTitle("");
            navigate("/");
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          Выход
        </MenuItem>
      </Menu>
    </header>
  );
};
