import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import price from "./Price.module.css";
import pageStyle from "./Page.module.css";
import { clsx, userLevels } from "../helpers";
import { useContext } from "react";
import { AuthContext } from "../App";

export const PriceInfo = ({ isAllPrice }) => {
  const { priceList, user } = useContext(AuthContext);
  const isSmallScreen = window.screen.width < 600;
  const currentPriceList = userLevels.filter(
    (lvl) => lvl.type === user.type || isAllPrice
  );

  return (
    <div className={pageStyle["page-content"]}>
      {priceList && (
        <div
          className={pageStyle["content-wrapper"]}
          style={isSmallScreen ? { padding: "10px 0" } : {}}
        >
          {currentPriceList.map(({ type, name }) => (
            <>
              <Typography variant='h5' color='primary' className={price.title}>
                Взрослые {isAllPrice && `(${name})`}
              </Typography>
              <Card classes={{ root: price.card }}>
                <CardContent
                  className={price.table}
                  style={{ flexWrap: isSmallScreen ? "wrap" : "nowrap" }}
                >
                  <div className={clsx(price.column, price.cell__title)}>
                    <div className={price.cell}></div>
                    <div className={price.cell}>1 сет</div>
                    <div className={price.cell}>1 час</div>
                    <div className={price.cell}>Утренние часы*</div>
                    <div className={price.cell}> Пакет новичок** </div>
                  </div>
                  {priceList[type].week && (
                    <div className={clsx(price.column, price.coast)}>
                      <div className={price.cell}>Будни</div>
                      <div className={price.cell}>
                        {priceList[type].week.set || "-"}
                      </div>
                      <div className={price.cell}>
                        {priceList[type].week.hour || "-"}
                      </div>
                      <div className={price.cell}>
                        {priceList[type].week.early || "-"}
                      </div>
                      <div className={price.cell}>
                        {priceList[type].week.newClient || "-"}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: isSmallScreen ? "flex" : "none",
                      borderTop: isSmallScreen
                        ? "1px solid rgb(0 0 0 / 20%)"
                        : "",
                    }}
                    className={clsx(price.column, price.cell__title)}
                  >
                    <div className={price.cell}></div>
                    <div className={price.cell}>1 сет</div>
                    <div className={price.cell}>1 час</div>
                    <div className={price.cell}>Утренние часы*</div>
                    <div className={price.cell}> Пакет новичок** </div>
                  </div>
                  {priceList[type].holiday && (
                    <div
                      className={clsx(price.column, price.coast)}
                      style={{
                        borderTop: isSmallScreen
                          ? "1px solid rgb(0 0 0 / 20%)"
                          : "",
                      }}
                    >
                      <div className={price.cell}>Выходные</div>
                      <div className={price.cell}>
                        {priceList[type].holiday.set || "-"}
                      </div>
                      <div className={price.cell}>
                        {priceList[type].holiday.hour || "-"}
                      </div>
                      <div className={price.cell}>
                        {priceList[type].holiday.early || "-"}
                      </div>
                      <div className={price.cell}>
                        {priceList[type].holiday.newClient || "-"}
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </>
          ))}

          <Typography variant='h5' color='primary' className={price.title}>
            Дети***
          </Typography>
          {priceList["CHILDREN"] && (
            <Card classes={{ root: price.card }}>
              <CardContent
                className={price.table}
                style={{ flexWrap: isSmallScreen ? "wrap" : "nowrap" }}
              >
                <div className={clsx(price.column, price.cell__title)}>
                  <div className={price.cell}></div>
                  <div className={price.cell}>1 сет</div>
                </div>
                {priceList["CHILDREN"].week && (
                  <div className={clsx(price.column, price.coast)}>
                    <div className={price.cell}>Будни</div>
                    <div className={price.cell}>
                      {priceList["CHILDREN"].week.set || "-"}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: isSmallScreen ? "flex" : "none",
                    borderTop: isSmallScreen
                      ? "1px solid rgb(0 0 0 / 20%)"
                      : "",
                  }}
                  className={clsx(price.column, price.cell__title)}
                >
                  <div className={price.cell}></div>
                  <div className={price.cell}>1 сет</div>
                </div>
                {priceList["CHILDREN"].holiday && (
                  <div
                    className={clsx(price.column, price.coast)}
                    style={{
                      borderTop: isSmallScreen
                        ? "1px solid rgb(0 0 0 / 20%)"
                        : "",
                    }}
                  >
                    <div className={price.cell}>Выходные</div>
                    <div className={price.cell}>
                      {priceList["CHILDREN"].holiday.set || "-"}
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          )}
          <Typography color='primary' className={price.note}>
            * - действуют в будние дни с 8:00 до 11:00
          </Typography>
          <Typography color='primary' className={price.note}>
            ** - В пакет входит экиперовка, подробный инструктаж, обучение на
            суше и 20 минут катания в воде.
          </Typography>
          <Typography color='primary' className={price.note}>
            *** - до 12 лет.
          </Typography>
          <Typography variant='h5' color='primary' className={price.title}>
            Услуги
          </Typography>
          {priceList["SERVICE"] && (
            <Card classes={{ root: price.card }}>
              <CardContent className={price.table}>
                <table className={price.fullWidth}>
                  <tbody>
                    {Object.values(priceList["SERVICE"]).map((service) => {
                      return (
                        <tr
                          className={clsx(price.cell, price.cell_left)}
                          key={service.name}
                        >
                          <td>{service.name}</td>
                          <td className={price.cell__price}>{service.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};
