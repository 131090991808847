import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useState } from "react";
import dialogStyle from "./Dialog.module.css";

export const WarningDialog = ({
  showDialog,
  handleClose,
  trainer,
  setTrainer,
  trainersList,
  setAcceptEquipClick,
  reservedTrainerSets,
}) => {
  const [newTariner, setNewTrainer] = useState("");
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>Внимание!</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ margin: "5px 0" }}>
          Выбранный вами тренер {trainer} занят в указанное Вами время:{" "}
          {reservedTrainerSets.join(", ")}
        </DialogContentText>
        <DialogContentText sx={{ margin: "5px 0" }}>
          Вы можете выбрать другого тренера.
        </DialogContentText>
        <div className={dialogStyle["trainer-select"]}>
          <FormControl fullWidth sx={{ minWidth: 120 }}>
            <InputLabel id='demo-simple-select-label'>
              Выберите тренера
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={newTariner}
              label=' Выберите тренера'
              onChange={(event) => {
                setNewTrainer(event.target.value);
              }}
            >
              <MenuItem value={"Без тренера"}>Без тренера</MenuItem>
              {trainersList
                .filter(({ name }) => name !== trainer)
                .map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отменить</Button>
        <Button
          onClick={() => {
            setTrainer(newTariner);
            setAcceptEquipClick(true);
          }}
          variant='contained'
        >
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
