import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import pageStyle from "../pages/Page.module.css";
import { regExpDic } from "../helpers";

export const AddFieldsForm = ({
  isGuest,
  phone,
  setPhone,
  fetchError,
  setFetchError,
  errorMessage,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  name,
  setName,
  secondName,
  setSecondName,
  birthday,
  setBirthday,
  isValidEmail,
  setIsValidEmail,
  isValidPhone,
  setIsValidPhone,
  inputsState,
  setInputsState,
}) => {
  function validate(el) {
    const regExpName = el.name;
    if (!el.value.length || !regExpDic[regExpName]) return true;
    return regExpDic[regExpName].test(el.value);
  }

  return (
    <>
      <TextField
        label='Телефон'
        name='phone'
        onBlur={(event) => {
          setIsValidPhone(validate(event.currentTarget));
        }}
        onChange={(event) => {
          const phoneString = event.currentTarget.value
            .trim()
            .replace(/\(|\)|-|[^0-9+]/g, "");
          setPhone(phoneString);
          fetchError && setFetchError(false);
        }}
        value={phone}
        error={!phone.length && !!errorMessage.length}
      />
      {!isValidPhone && (
        <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
          Номер телефона не соответствует виду '+7ХХХХХХХХХХ или 8ХХХХХХХХХХ'
        </Typography>
      )}
      {!isGuest && (
        <>
          <TextField
            label='Пароль'
            name='password'
            type={inputsState.showPassword ? "text" : "password"}
            onChange={(event) => {
              setPassword(event.currentTarget.value);
              fetchError && setFetchError(false);
            }}
            error={
              (!password.length && !!errorMessage.length) ||
              password !== confirmPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() =>
                      setInputsState({
                        ...inputsState,
                        showPassword: !inputsState.showPassword,
                      })
                    }
                  >
                    {inputsState.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='Повторите пароль'
            name='confirmPassword'
            type={inputsState.showConfirmPassword ? "text" : "password"}
            onChange={(event) => {
              setConfirmPassword(event.currentTarget.value);
              fetchError && setFetchError(false);
            }}
            error={!password.length && !!errorMessage.length}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle confirmPassword visibility'
                    onClick={() =>
                      setInputsState({
                        ...inputsState,
                        showConfirmPassword: !inputsState.showConfirmPassword,
                      })
                    }
                  >
                    {inputsState.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      <TextField
        label='Имя'
        name='name'
        onBlur={(event) => {}}
        onChange={(event) => {
          setName(event.currentTarget.value);
          fetchError && setFetchError(false);
        }}
        error={!name.length && !!errorMessage.length}
      />
      <TextField
        label='Фамилия'
        name='secondName'
        onBlur={(event) => {}}
        onChange={(event) => {
          setSecondName(event.currentTarget.value);
          fetchError && setFetchError(false);
        }}
        error={!secondName.length && !!errorMessage.length}
      />
      <TextField
        label='email'
        name='email'
        onBlur={(event) => {
          setIsValidEmail(validate(event.currentTarget));
        }}
        onChange={(event) => {
          setEmail(event.currentTarget.value);
          fetchError && setFetchError(false);
        }}
        error={(!email.length && !!errorMessage.length) || !isValidEmail}
      />
      {!isValidEmail && (
        <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
          Email не соответствует виду 'example@email.ru'
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          label='Дата рождения'
          inputFormat='DD.MM.yyyy'
          value={birthday}
          onChange={(newDate) => {
            setBirthday(newDate);
          }}
          renderInput={(params) => <TextField {...params} size='medium' />}
        />
      </LocalizationProvider>
      {!!errorMessage.length && (
        <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};
