import {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import rides from "./Rides.module.css";
import moment from "moment";
import {
  fetchViewSets,
  fetchDeleteTikets,
  fetchRidesApply,
  fetchTrainersReserved,
  fetchChangeTrainer,
} from "../http/index";
import { AuthContext } from "../App";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import pageStyle from "./Page.module.css";
import { clsx } from "../helpers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const RidesAdmin = () => {
  const [tab, setTab] = useState(0);
  const [isMy, setIsMy] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [timerReset, setTimerReset] = useState(true);
  const [date, setDate] = useState(moment());
  const [time, setTime] = useState({});
  const refTime = useRef(null);
  const { user } = useContext(AuthContext);
  const [trainersList, setTrainersList] = useState([]);
  const [choosedTrainer, setChoosedTrainer] = useState({});

  useEffect(() => {
    setChoosedTrainer({});
    handleViewClick();
  }, [tab, date]);

  useEffect(() => {
    if (timerReset) {
      handleViewClick();
      setTimerReset(false);
      setTimeout(() => setTimerReset(true), 300000);
    }
  }, [timerReset]);

  useEffect(() => {
    fetchTrainersReserved({ date: date.format("DD.MM.yyyy") }).then(
      ({ trainers }) => {
        setTrainersList(trainers);
      }
    );
  }, [date]);

  useLayoutEffect(() => {
    if (refTime.current) refTime.current.scrollIntoView({ behavior: "smooth" });
  }, [refTime.current]);

  const handleViewClick = async () => {
    const reserve = {
      date: date.format("DD.MM.yyyy"),
      type: tab ? "track2" : "simple",
    };
    const res = await fetchViewSets(reserve);
    if (res.message) {
      console.log(res.message);
      return;
    }

    setTime(res.time);

    let choosedTrainerDraft = {};
    for (const set in res.time) {
      if (res.time[set].trainer === "Без тренера")
        choosedTrainerDraft[set] = res.time[set].trainer;
    }

    setChoosedTrainer(choosedTrainerDraft);
  };

  const timeNow = moment().format("HH:mm").split(":").join("");

  const handleDeleteTime = async (timeSet) => {
    let simpleSets = [];
    let track2Sets = [];
    const isGroupSets = time[timeSet].category !== "сет";

    if (tab) {
      if (isGroupSets) {
        track2Sets = time[timeSet].group;
      } else track2Sets.push(timeSet);
    } else {
      if (isGroupSets) {
        simpleSets = time[timeSet].group;
      } else simpleSets.push(timeSet);
    }

    const res = await fetchDeleteTikets({
      date: date.format("DD.MM.yyyy"),
      simpleSets,
      track2Sets,
      groups: [time[timeSet].group],
      trainers: {
        [time[timeSet].trainer]: isGroupSets ? time[timeSet].group : [timeSet],
      },
      user: { id: time[timeSet].id, uid: time[timeSet].uid },
    });

    if (res && res.result) {
      handleViewClick();
    }
  };

  const sortedTimeList = Object.keys(time).sort((a, b) => {
    if (a.length < 5) a = "0" + a;
    if (b.length < 5) b = "0" + b;
    return a.localeCompare(b);
  });

  const handleApply = async (currentTime) => {
    const res = await fetchRidesApply({
      date: date.format("DD.MM.yyyy"),
      type: tab ? "track2" : "simple",
      set: currentTime,
    });

    if (res.result)
      setTime({
        ...time,
        [currentTime]: { ...time[currentTime], apply: true },
      });
  };

  const changeTrainer = (currentTime, value) => {
    setChoosedTrainer({ ...choosedTrainer, [currentTime]: value });
  };

  const handleApplyTrainer = async (set) => {
    if (!!choosedTrainer[set] && choosedTrainer[set] !== "Без тренера") {
      const response = await fetchChangeTrainer({
        date: date.format("DD.MM.yyyy"),
        set,
        trainer: choosedTrainer[set],
        type: tab ? "track2" : "simple",
        userId: time[set].uid ? time[set].uid : time[set].id,
      });

      if (response.result) {
        setTime({
          ...time,
          [set]: { ...time[set], trainer: choosedTrainer[set] },
        });
      }
    }
  };

  return (
    <div className={clsx(pageStyle["page-content"], pageStyle["center"])}>
      <div className={pageStyle["content-wrapper"]}>
        <div sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(e, newTab) => {
              setTab(newTab);
            }}
            aria-label='Расписание'
            centered
          >
            <Tab label='Стандарт' {...a11yProps(0)} />
            <Tab label='PRO' {...a11yProps(1)} />
          </Tabs>
        </div>
        <div className={rides.calendar}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='ru'>
            <MobileDatePicker
              label='Выберите дату'
              inputFormat='DD.MM.yyyy'
              value={date}
              onChange={(newDate) => setDate(newDate)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className={rides.content}>
          <Chip
            label='Показать мои'
            color='success'
            variant={isMy ? "filled" : "outlined"}
            onClick={() => {
              setIsMy(!isMy);
              setIsFree(false);
            }}
          />
          <Chip
            label='Показать свободные'
            color='primary'
            variant={isFree ? "filled" : "outlined"}
            onClick={() => {
              setIsMy(false);
              setIsFree(!isFree);
            }}
          />
        </div>
        <div className={clsx(rides.content, pageStyle["page-content_500"])}>
          {sortedTimeList
            .filter((currentTime) => {
              if (isMy)
                return (
                  time[currentTime].trainer ===
                  `${user.name} ${user.secondName}`
                );
              if (isFree) return time[currentTime].trainer === "Без тренера";
              return true;
            })
            .map((currentTime, index) => {
              const timeSetString = currentTime.split(":").join("");
              const timeSet = parseInt(timeSetString, 10);
              const timeNowNumber = parseInt(timeNow, 10);
              const isRefElem =
                timeNowNumber >= timeSet && timeNowNumber < timeSet + 15;

              return (
                <div
                  key={currentTime}
                  className={rides.set}
                  ref={isRefElem ? refTime : null}
                >
                  <Chip
                    key={currentTime}
                    label={currentTime}
                    variant={isRefElem ? "filled" : "outlined"}
                    color='primary'
                    deleteIcon={<DeleteForeverIcon />}
                    onDelete={
                      user.role === "OWNER" || user.role === "ADMIN"
                        ? () => handleDeleteTime(currentTime)
                        : () => {}
                    }
                  />
                  <div className={rides.set__info}>
                    <Typography color='primary'>Фамилия Имя:</Typography>
                    <Typography>
                      {time[currentTime].secondName} {time[currentTime].name}
                    </Typography>
                    <Typography color='primary'>Телефон:</Typography>
                    <Typography>{time[currentTime].phone}</Typography>
                    <Typography color='primary'>Тренер:</Typography>

                    {(user.role !== "OWNER" && user.role !== "ADMIN") ||
                    time[currentTime].trainer !== "Без тренера" ? (
                      <Typography>{time[currentTime].trainer}</Typography>
                    ) : (
                      <FormControl size='small'>
                        <Select
                          id={"trainer" + currentTime}
                          value={choosedTrainer[currentTime]}
                          onChange={(event) => {
                            changeTrainer(currentTime, event.target.value);
                          }}
                        >
                          <MenuItem value={"Без тренера"}>Без тренера</MenuItem>
                          {trainersList
                            .filter((item) => !item.payload[currentTime])
                            .map((trainer) => (
                              <MenuItem value={trainer.name} key={trainer.name}>
                                {trainer.name}
                              </MenuItem>
                            ))}
                        </Select>
                        <Chip
                          color='primary'
                          label='Назначить тренера'
                          variant='outlined'
                          classes={{ root: rides.set__control }}
                          onClick={() => handleApplyTrainer(currentTime)}
                        />
                      </FormControl>
                    )}
                    <Typography color='primary'>Оборудование:</Typography>
                    <Typography>{time[currentTime].equips}</Typography>
                    <Typography color='primary'>Категория:</Typography>
                    <Typography>{time[currentTime].category}</Typography>
                    <div className={rides.set__apply}>
                      <Typography color='primary'>Статус:</Typography>{" "}
                      <Chip
                        color={time[currentTime].apply ? "success" : "error"}
                        label={
                          time[currentTime].apply
                            ? "Подтверждено"
                            : "Не подтверждено"
                        }
                        variant={time[currentTime].apply ? "filed" : "outlined"}
                      />
                    </div>

                    {!time[currentTime].apply &&
                      (user.role === "OWNER" || user.role === "ADMIN") && (
                        <Chip
                          color={"success"}
                          label={"Подтвердить"}
                          variant={"outlined"}
                          sx={{ marginTop: "8px" }}
                          onClick={() => handleApply(currentTime)}
                        />
                      )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
