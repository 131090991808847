import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import pageStyle from "./Page.module.css";
import { clsx, debounce } from "../helpers";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useEffect, useContext } from "react";
import {
  fetchAllGuests,
  fetchFindGuests,
  fetchSignSaveGuest,
  fetchCommentUser,
  fetchPageGuests,
} from "../http";
import { AuthContext } from "../App";
import { UserCard } from "../components/UserCard";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Guests = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [guests, setGuests] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { user: currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (
      !process.env.REACT_APP_PAGINATION_COUNT ||
      process.env.REACT_APP_PAGINATION_COUNT === "0"
    ) {
      setIsLoading(true);
      fetchAllGuests().then((result) => {
        setIsLoading(false);
        setGuests(result.guests);
        setSearchResult(result.guests);
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_PAGINATION_COUNT !== "0") {
      setIsLoading(true);
      const range = Number(process.env.REACT_APP_PAGINATION_COUNT);
      fetchPageGuests({ from: (page - 1) * range, range }).then((result) => {
        result.count && setTotalPage(Math.ceil(result.count / range));
        setIsLoading(false);
        setGuests(result.guests);
        setSearchResult(result.guests);
      });
    }
  }, [page]);

  const handleChangeText = (event) => {
    const searchString = (event.target.value = event.target.value.trim());
    setIsSearch(!!searchString.length);
    if (searchString)
      fetchFindGuests({ searchString }).then(
        (result) => result.guests && setSearchResult(result.guests)
      );
    else {
      setSearchResult(guests);
    }
  };

  const showUserInfo = (user) => {
    setSelectedUser(user);
    setShowCard(true);
  };

  const handleCloseUserInfo = () => {
    setSelectedUser({});
    setShowCard(false);
  };

  const signSave = async (userUid) => {
    const result = await fetchSignSaveGuest({ uid: userUid });

    if (result.success) {
      const updGuests = guests.map((item) => {
        if (item.uid === userUid) item.isSigned = true;
        return item;
      });
      setGuests(updGuests);
      setSearchResult(updGuests);
    }
  };

  const changeCommentUser = async ({ id, uid, newComment }) => {
    const result = await fetchCommentUser({ id, uid, comment: newComment });

    if (result.success) {
      setGuests(
        guests.map((item) => {
          if (item.id === id) item.comment = newComment;
          return item;
        })
      );
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={pageStyle["page-content"]}>
      <TextField
        fullWidth
        onChange={debounce(handleChangeText, 500)}
        placeholder='Введите имя или фамилию'
        sx={{ background: "#fff", marginBottom: "20px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {!!searchResult.length && (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ marginBottom: "20px" }}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{ width: "100%" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Коментарии</Typography>
              <Typography sx={{ color: "text.secondary", marginLeft: "auto" }}>
                Найдено{" "}
                {
                  searchResult.filter(
                    (user) => user.comment && user.comment.length
                  ).length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {searchResult
                .filter((user) => user.comment && user.comment.length)
                .map((user) => (
                  <div key={user.uid}>
                    <Typography>
                      {`${user.name} ${user.secondName} (${
                        user.uid.split("-")[0]
                      }):`}
                    </Typography>
                    <Typography>{user.comment}</Typography>
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {!!searchResult.length && (
        <div className={pageStyle["content-wrapper"]}>
          {isLoading ? (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <CircularProgress />
            </Grid>
          ) : (
            <List sx={{ width: "100%", bgcolor: "transparent" }}>
              {searchResult.map((user, index) => (
                <ListItem
                  className={clsx(
                    pageStyle["user-list"],
                    pageStyle["user-list--pointer"]
                  )}
                  alignItems='flex-start'
                  key={user.id.toString() + index}
                  onClick={() => {
                    showUserInfo(user);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>{index + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${user.name} ${user.secondName} (${
                      user.uid.split("-")[0] + "-" + user.uid.split("-")[2]
                    })`}
                    secondary={
                      <>
                        {user.phone.slice(0, user.phone[0] === "+" ? 5 : 4)}
                        ...
                        {user.phone.slice(-3)} {user.role}
                      </>
                    }
                    secondaryTypographyProps={{
                      sx: { display: "inline" },
                      component: "span",
                      variant: "body2",
                      color: "text.primary",
                    }}
                  />
                  {!user.isSigned && (
                    <Chip
                      sx={{
                        marginLeft: "10px",
                        alignSelf: "center",
                      }}
                      variant='filled'
                      label='Техника безопасности!'
                      color='warning'
                    />
                  )}
                </ListItem>
              ))}
            </List>
          )}
          {!isSearch && (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              {" "}
              <Pagination
                variant='outlined'
                color='primary'
                page={page}
                onChange={(event, val) => setPage(val)}
                count={totalPage}
              />
            </Grid>
          )}
          {showCard && (
            <UserCard
              showDialog={showCard}
              user={selectedUser}
              handleClose={handleCloseUserInfo}
              signSave={signSave}
              currentUser={currentUser}
              changeCommentUser={changeCommentUser}
            />
          )}
        </div>
      )}
    </div>
  );
};
