import Chip from "@mui/material/Chip";

export const CustomChip = ({ label, onClick, disabled, checked }) => {
  return (
    <Chip
      style={{ minWidth: 60 }}
      variant={!checked && !disabled ? "outlined" : "filled"}
      color={disabled ? "default" : "primary"}
      label={label}
      onClick={() => {
        !disabled && onClick && onClick(checked);
      }}
    />
  );
};
