import { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CustomChip } from "../components/CustomChip";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  fetchReserve,
  fetchRservedSets,
  fetchTrainersReserved,
  fetchBonusFree,
} from "../http/index";
import { AuthContext } from "../App";
import { AcceptReserveDialog } from "../components/AcceptReserveDialog";
import { ConfigDialog } from "../components/ConfigDialog";
import { WarningDialog } from "../components/WarningDialog";
import Chip from "@mui/material/Chip";
import pageStyle from "./Page.module.css";
import rides from "./Rides.module.css";
import { clsx } from "../helpers";
import { ErrorDialog } from "../components/ErrorDialog";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const cache = createCache({
  key: "css",
  prepend: true,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const RidesInfo = ({ isGuest, guest, setGuest }) => {
  const [tab, setTab] = useState(0);
  const [date, setDate] = useState(moment());
  const [weekDay, setWeekDay] = useState(date.weekday());
  const [schedules, setSchedules] = useState([]);
  const [sets, setSets] = useState([]);
  const [reservedSets, setReservedSets] = useState([]);
  const [trainer, setTrainer] = useState("");
  const [trainersList, setTrainersList] = useState([]);
  const [showFinalDialog, setShowFinalDialog] = useState(false);
  const [showEquipDialog, setShowEquipDialog] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [acceptEquipClick, setAcceptEquipClick] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reservedTrainerSets, setReservedTrainerSets] = useState([]);
  const { user, setUser, priceList } = useContext(AuthContext);
  const [equips, setEquips] = useState({});
  const [category, setCategory] = useState("сет");
  const [clientSets, setClientSets] = useState([]);
  const [hourSets, setHourSets] = useState([]);
  const [spendFreeSets, setSpendFreeSets] = useState(0);

  useEffect(() => {
    let hoursEffect = [];
    let minutsEffect = ["00"];
    let timeArray = [];

    for (let i = 8; i < 20; i++) {
      hoursEffect.push(i);
    }

    for (let i = 15; i < 60; i += 15) {
      minutsEffect.push(i.toString());
    }

    hoursEffect.forEach((hourArr) =>
      minutsEffect.forEach((minArr) => timeArray.push(`${hourArr}:${minArr}`))
    );

    setSchedules(timeArray);
  }, []);

  const reloadSets = () => {
    fetchRservedSets({
      date: date.format("DD.MM.yyyy"),
      type: tab ? "track2" : "simple",
    }).then(({ sets: reserved }) => {
      setReservedSets(reserved ? reserved : []);
    });
  };

  useEffect(() => {
    reloadSets();
    setSets([]);
  }, [date, tab]);

  useEffect(() => {
    fetchTrainersReserved({ date: date.format("DD.MM.yyyy") }).then(
      ({ trainers }) => {
        setTrainersList(trainers);
      }
    );
  }, [date]);

  useEffect(() => {
    if (acceptEquipClick) {
      acceptEquips();
      setAcceptEquipClick(false);
    }
  }, [acceptEquipClick]);

  useEffect(() => {
    setEquips(priceList["SERVICE"]);
  }, [priceList]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const getGroup = () => {
    switch (category) {
      case "час":
        return hourSets;
      case "новичек":
        return clientSets;
      default:
        return [];
    }
  };

  const handleReservedClick = async () => {
    const theDay = date.format("DD.MM.yyyy");

    const reserve = {
      date: theDay,
      sets,
      type: tab ? "track2" : "simple",
      user: isGuest ? guest : user,
      trainer,
      choosedEquips: Object.keys(equips)
        .filter(
          (equip) =>
            equips[equip].checked ||
            (equips[equip].include && equips[equip].include.includes(category))
        )
        .map((equip) => equips[equip].name)
        .join(", "),
      category,
      spendFreeSets,
      group: getGroup(),
      isGuest,
    };

    const { reservedSets: reserved, message } = await fetchReserve(reserve);

    if (message) {
      setErrorMessage(message);
      handleClose();
      setShowErrorDialog(true);
      return;
    }

    setReservedSets(reserved);
    const { trainers } = await fetchTrainersReserved({ date: theDay });

    setTrainersList(trainers);
    setShowFinalDialog(false);
    setSets([]);
    setClientSets([]);
    setHourSets([]);
    if (isGuest) {
      setGuest && setGuest({});
    } else {
      fetchBonusFree().then(({ freeSets }) => {
        freeSets > 0 && setUser({ ...user, freeSets });
      });
    }
  };

  const handleClose = () => {
    setShowFinalDialog(false);
    setShowEquipDialog(false);
    setShowWarningDialog(false);
  };

  const checkValidTrainer = () => {
    const trainerPayload = trainersList.find((item) => item.name === trainer);
    if (!trainerPayload) return [];

    const resultValidTrainer = sets.filter((time) =>
      Object.keys(trainerPayload.payload).includes(time)
    );
    setReservedTrainerSets(resultValidTrainer);
    return resultValidTrainer;
  };

  const acceptEquips = () => {
    if (!trainer.length) setTrainer("Без тренера");
    setShowEquipDialog(false);
    setShowWarningDialog(false);
    const resultValidTrainer = checkValidTrainer();
    if (!resultValidTrainer.length) {
      setShowFinalDialog(true);
    } else {
      setShowWarningDialog(true);
    }
  };

  const getHourArray = (indexArray) => {
    return [
      schedules[indexArray],
      schedules[indexArray + 1],
      schedules[indexArray + 2],
      schedules[indexArray + 3],
    ];
  };

  const getNewClientSets = (indexArray) => {
    return [schedules[indexArray], schedules[indexArray + 1]];
  };
  const clearAll = () => {
    setSets([]);
    setClientSets([]);
    setHourSets([]);
  };

  moment.locale("ru");
  return (
    <CacheProvider value={cache}>
      <div className={clsx(pageStyle["page-content"], pageStyle["center"])}>
        <div className={pageStyle["content-wrapper"]}>
          <div sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="Расписание"
              centered
            >
              <Tab
                label="Стандарт"
                {...a11yProps(0)}
                classes={{ root: rides.tab, selected: rides.tab__selected }}
              />
              <Tab
                label="PRO"
                {...a11yProps(1)}
                classes={{ root: rides.tab, selected: rides.tab__selected }}
              />
            </Tabs>
          </div>
          <div className={rides.calendar}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="Выберите дату"
                inputFormat="DD.MM.yyyy"
                value={date}
                disablePast
                showDaysOutsideCurrentMonth
                onChange={(newDate) => {
                  setDate(newDate);
                  setWeekDay(newDate.weekday());
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputProps={{ classes: { input: rides.inputDate } }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className={rides.content}>
            <Chip
              label="сет"
              color="primary"
              variant={category === "сет" ? "filled" : "outlined"}
              onClick={() => {
                setCategory("сет");
                clearAll();
              }}
              sx={{ width: "80px" }}
            />
            <Chip
              label="По часу"
              color="success"
              variant={category === "час" ? "filled" : "outlined"}
              onClick={() => {
                setCategory(category === "час" ? "сет" : "час");
                clearAll();
              }}
            />
            <Chip
              label="Новичок"
              color="secondary"
              variant={category === "новичек" ? "filled" : "outlined"}
              onClick={() => {
                setCategory(category === "новичек" ? "сет" : "новичек");
                clearAll();
              }}
            />
            <Chip
              label="Очистить всё"
              color="error"
              variant="outlined"
              onClick={clearAll}
              sx={{ width: "100%" }}
            />
          </div>
          <div className={rides.content}>
            {schedules
              .filter((currentTime, index, array) => {
                if (category === "час") {
                  const isLastSets =
                    index === array.length - 1 ||
                    index === array.length - 2 ||
                    index === array.length - 3;
                  return (
                    !getHourArray(index).some((time) =>
                      reservedSets.includes(time)
                    ) && !isLastSets
                  );
                }

                if (category === "новичек") {
                  const isLastSets = index === array.length - 1;
                  return (
                    !getNewClientSets(index).some((time) =>
                      reservedSets.includes(time)
                    ) && !isLastSets
                  );
                }
                return true;
              })
              .map((currentTime) => {
                const currentTimeNumber = parseInt(
                  currentTime.split(":").join(""),
                  10
                );
                const timeNow = parseInt(
                  moment().format("HH:mm").split(":").join(""),
                  10
                );
                const isPast =
                  date.format("DD.MM.yyyy") === moment().format("DD.MM.yyyy") &&
                  currentTimeNumber < timeNow;

                const isStaffOnly =
                  currentTimeNumber < 900 &&
                  user.role !== "ADMIN" &&
                  user.role !== "OWNER";

                return (
                  <CustomChip
                    key={currentTime}
                    label={currentTime}
                    disabled={
                      reservedSets.includes(currentTime) ||
                      isPast ||
                      isStaffOnly
                    }
                    checked={sets.includes(currentTime)}
                    onClick={(checked) => {
                      if (category === "час") {
                        const index = schedules.findIndex(
                          (item) => item === currentTime
                        );

                        if (!checked) {
                          const hourArray = getHourArray(index);
                          let newSet = [...sets];
                          let newHSet = [...hourSets];
                          hourArray.forEach((timeSet) => {
                            const hour = hourSets.find((item) =>
                              item.includes(timeSet)
                            );
                            if (hour) {
                              newSet = newSet.filter(
                                (yetSet) => !hour.includes(yetSet)
                              );
                              newHSet = newHSet.filter(
                                (yetHour) => !yetHour.includes(timeSet)
                              );
                            }
                          });

                          setSets([...newSet, ...hourArray]);
                          setHourSets([...newHSet, hourArray]);
                        } else {
                          const hour = hourSets.find((item) =>
                            item.includes(currentTime)
                          );
                          setSets(sets.filter((set) => !hour.includes(set)));
                          setHourSets(
                            hourSets.filter(
                              (item) => !item.includes(currentTime)
                            )
                          );
                        }
                        return;
                      }

                      if (category === "новичек") {
                        const index = schedules.findIndex(
                          (item) => item === currentTime
                        );

                        if (!checked) {
                          setSets([...sets, ...getNewClientSets(index)]);
                          setClientSets([
                            ...clientSets,
                            getNewClientSets(index),
                          ]);
                        } else {
                          const pair = clientSets.find((item) =>
                            item.includes(currentTime)
                          );
                          setSets(sets.filter((set) => !pair.includes(set)));
                          setClientSets(
                            clientSets.filter(
                              (item) => !item.includes(currentTime)
                            )
                          );
                        }
                        return;
                      }

                      !checked
                        ? setSets([...sets, currentTime])
                        : setSets(sets.filter((time) => time !== currentTime));
                    }}
                  />
                );
              })}
          </div>
          <div style={{ height: 42 }}></div>
          <div className={rides.bottom}>
            <Button
              variant="contained"
              fullWidth
              disabled={!sets.length && !isGuest}
              classes={{ root: rides.button }}
              onClick={() =>
                isGuest && !sets.length
                  ? setGuest({})
                  : setShowEquipDialog(true)
              }
            >
              {isGuest && !sets.length ? "Отмена" : "Забронировать"}
            </Button>
          </div>
        </div>

        {showFinalDialog && (
          <AcceptReserveDialog
            showDialog={showFinalDialog}
            handleClose={handleClose}
            trainer={trainer}
            equips={equips}
            sets={sets}
            clientSets={clientSets}
            hourSets={hourSets}
            weekDay={weekDay}
            handleReservedClick={handleReservedClick}
            category={category}
            spendFreeSets={spendFreeSets}
            isGuest={isGuest}
            guest={guest}
          />
        )}
        {showEquipDialog && (
          <ConfigDialog
            showDialog={showEquipDialog}
            handleClose={handleClose}
            trainer={trainer}
            setTrainer={setTrainer}
            equips={equips}
            setEquips={setEquips}
            setAcceptEquipClick={setAcceptEquipClick}
            trainersList={trainersList}
            category={category}
            sets={sets}
            spendFreeSets={spendFreeSets}
            setSpendFreeSets={setSpendFreeSets}
            freeSets={!!user.freeSets ? user.freeSets : 0}
          />
        )}
        {showWarningDialog && (
          <WarningDialog
            showDialog={showWarningDialog}
            handleClose={handleClose}
            trainer={trainer}
            setTrainer={setTrainer}
            setAcceptEquipClick={setAcceptEquipClick}
            trainersList={trainersList}
            reservedTrainerSets={reservedTrainerSets}
          />
        )}
        {showErrorDialog && (
          <ErrorDialog
            showDialog={showErrorDialog}
            handleCloseDialog={() => {
              setShowErrorDialog(false);
              reloadSets();
            }}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </CacheProvider>
  );
};
