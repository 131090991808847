import { useEffect, useState } from "react";
import login from "./Login.module.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import {
  fetchConfirmCode,
  fetchRegistration,
  fetchAgainCode,
  fetchResetCode,
  fetchConfigReg,
} from "../http/index";
import { regExpDic } from "../helpers";
import moment from "moment";
import pageStyle from "./Page.module.css";
import { AddFieldsForm } from "../components/AddFieldsForm";

export const Registration = () => {
  let navigate = useNavigate();
  const registrationInfo = localStorage.getItem("registration");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [birthday, setBirthday] = useState(moment());
  const [phone, setPhone] = useState(
    registrationInfo ? JSON.parse(registrationInfo).phone : ""
  );
  const [sms, setSms] = useState("");
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [inputsState, setInputsState] = useState({
    showPassword: false,
    showConfirmPassword: false,
    showSmsCode: !!registrationInfo,
  });
  const [isDisabledButtons, setIsDisabledButtons] = useState(false);

  useEffect(() => {
    fetchConfigReg().then(({ sms_enabled }) => {
      setSmsEnabled(sms_enabled);
    });
  }, []);

  const handleRegistration = async (event) => {
    event.preventDefault();
    if (!phone || !password || !name || !secondName) {
      setErrorMessage("Заполните все обязательные поля");
      return;
    }

    if (regExpDic.phone.test(phone)) {
      if (phone.length === 11 && phone.substring(0, 2) !== "89") {
        setIsValidPhone(false);
        return;
      }
      if (phone.length === 12 && phone.substring(0, 3) !== "+79") {
        setIsValidPhone(false);
        return;
      }
    } else return;

    setErrorMessage("");

    setIsDisabledButtons(true);
    const result = await fetchRegistration({
      email,
      password,
      name,
      secondName,
      birthday: birthday.format("DD.MM.yyyy"),
      phone,
    });
    setIsDisabledButtons(false);

    if (!result) {
      setErrorMessage("Нет соединения с сервером");
      setFetchError(true);
      return;
    }

    if (result && result.message) {
      setErrorMessage(result.message);
      setFetchError(true);
      return;
    }
    if (result.result) {
      const { userId, phone } = result;
      setInputsState({ ...inputsState, showSmsCode: true });
      localStorage.setItem("registration", JSON.stringify({ userId, phone }));
    }
  };

  const confirmCode = async (event) => {
    event.preventDefault();

    setFetchError(true);
    setErrorMessage("");

    if (!sms) {
      setErrorMessage("Заполните все обязательные поля");
      return;
    }

    const userId = JSON.parse(registrationInfo).userId;

    setIsDisabledButtons(true);
    const result = await fetchConfirmCode({
      userId,
      phone,
      sms,
    });
    setIsDisabledButtons(false);
    if (result && result.message) {
      setErrorMessage(result.message);
      setFetchError(true);
      return;
    }

    if (result && result.success) {
      localStorage.removeItem("registration");
      navigate("/rules", { replace: true });
    }
  };

  const againCode = async () => {
    setFetchError(true);
    setErrorMessage("");
    setSuccessMessage("");

    const userId = JSON.parse(registrationInfo).userId;
    const result = await fetchAgainCode({
      userId,
      phone,
    });

    if (result && result.message) {
      setErrorMessage(result.message);
      setFetchError(true);
      return;
    }

    if (result.success) {
      setSuccessMessage("Выслан новый код на ваш номер");
    }
  };

  const resetCode = async () => {
    setFetchError(true);
    setErrorMessage("");

    const userId = JSON.parse(registrationInfo).userId;
    const result = await fetchResetCode({
      userId,
      phone,
    });

    if (result && result.message) {
      setErrorMessage(result.message);
      setFetchError(true);
      return;
    }

    if (result.success) {
      localStorage.removeItem("registration");
      navigate("/rules", { replace: true });
    }
  };

  return (
    <div className={login.registration}>
      {!inputsState.showSmsCode && (
        <form className={login.form} onSubmit={handleRegistration}>
          <AddFieldsForm
            phone={phone}
            setPhone={setPhone}
            fetchError={fetchError}
            setFetchError={setFetchError}
            errorMessage={errorMessage}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            name={name}
            setName={setName}
            secondName={secondName}
            setSecondName={setSecondName}
            birthday={birthday}
            setBirthday={setBirthday}
            isValidEmail={isValidEmail}
            setIsValidEmail={setIsValidEmail}
            isValidPhone={isValidPhone}
            setIsValidPhone={setIsValidPhone}
            inputsState={inputsState}
            setInputsState={setInputsState}
          />
          <Button
            variant="contained"
            type="submit"
            classes={{ root: login.button }}
            disabled={isDisabledButtons}
          >
            Зарегистрироваться
          </Button>
          <Button
            variant="text"
            classes={{ root: login.button }}
            onClick={() => {
              navigate("/", { replace: true });
            }}
            disabled={isDisabledButtons}
          >
            Войти
          </Button>
        </form>
      )}
      {inputsState.showSmsCode && (
        <form className={login.form} onSubmit={confirmCode}>
          {phone && (
            <Typography>
              {smsEnabled
                ? "Код отправлен на номер"
                : "Администратор свяжется с Вами в рабочие часы по телефону "}{" "}
              {phone.slice(0, phone[0] === "+" ? 5 : 4)}
              ...
              {phone.slice(-3)}
            </Typography>
          )}
          {smsEnabled ? (
            <>
              <TextField
                label="sms код"
                name="sms"
                onChange={(event) => {
                  setSms(event.currentTarget.value.toLocaleUpperCase());
                  fetchError && setFetchError(false);
                }}
                value={sms}
                error={!sms.length && !!errorMessage.length}
              />
              {!!errorMessage.length && (
                <Typography
                  color={"#d32f2f"}
                  className={pageStyle["error-text"]}
                >
                  {errorMessage}
                </Typography>
              )}
              {!!successMessage.length && (
                <Typography
                  color={"#1b5e20"}
                  className={pageStyle["error-text"]}
                >
                  {successMessage}
                </Typography>
              )}
              <Button
                variant="contained"
                type="submit"
                classes={{ root: login.button }}
                disabled={isDisabledButtons}
              >
                Подтвердить
              </Button>
              <Button
                variant="text"
                classes={{ root: login.button }}
                onClick={againCode}
              >
                Отправить код заново
              </Button>
            </>
          ) : (
            <Button
              variant="text"
              classes={{ root: login.button }}
              onClick={resetCode}
            >
              Перейти к правилам посещения
            </Button>
          )}
        </form>
      )}
    </div>
  );
};
