import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const WarningPasswordDialog = ({ showDialog, handleClose, id }) => {
  let navigate = useNavigate();
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>Внимание!</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ margin: "5px 0" }}>
          Вам установлен пароль по умолчанию. Для безопасности дальнейшего
          использования текущего профиля, необходимо сменить пароль.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Потом</Button>
        <Button
          onClick={() => {
            navigate("/user/" + id);
            handleClose();
          }}
          variant='contained'
        >
          Сменить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
