import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DangerousIcon from "@mui/icons-material/Dangerous";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import pageStyle from "./Page.module.css";
import styleRules from "./Rules.module.css";
import { clsx } from "../helpers";

export const RulesInfo = () => {
  const info = [
    "Перед посещением зоны катания Райдер обязан ознакомиться с настоящими Правилами и строго их соблюдать",

    "Запись на катание осуществляется по телефону, на сайте Парка http://wake-tantrum.ru и в приложении для iPhone и Android",

    "Услуги Парка предоставляются после 100% оплаты катания или приобретения абонемента/сертификата.",

    "Осуществляя оплату катания, абонемента/сертификата, Райдер подтверждает свое согласие с настоящими Правилами",

    "Если в расписании имеются свободные Сеты, время катания можно продлить, по согласованию с Администратором, оплата продленного времени катания осуществляется непосредственно после катания",

    "Рекомендуемое время явки на катание за 15 минут до ее начала. Это время необходимо, для того чтобы подобрать вейкборд, гидрокостюм и другое оборудование, переодеться, прослушать вводный инструктаж и дойти до стартовой зоны.",

    "Опоздавшие Райдеры катаются столько времени сколько останется от оплаченного Сета. Стоимость катания не меняется, оплата за не использованное время катания не возвращается",

    "Отмена бронирования: 1) при отмене бронирования со стороны Райдера менее чем за 24 часа - Парк имеет право оставить 50% оплаты за катание себе (50% минут забронированного времени если бронирование произведено по абонементу/сертификату); 2) при отмене бронирования со стороны Райдера более чем за 24 часа допускается перенести катание по согласованию с Администрацией Парка; 3) при отмене бронирования со стороны Парка в результате форс-мажоров, таких как погодные условия и иных причин, бронирование переносится на другой день (на выбор Райдера).",

    "Услуги, предоставляемые Парком, относятся к экстремальным, производя их оплату, Райдер осознает это и берет на себя все риски, связанные с возможным причинением вреда своему здоровью, либо здоровью несовершеннолетних, законным представителем которых он является.",

    "Соглашаясь с настоящими Правилами, Райдер подтверждает отсутствие у него или у несовершеннолетних, законным представителем которых он является, каких-либо медицинских и иных противопоказаний для пользования услугами Парка, осознает возможные последствия нарушения им настоящих Правил, и несет ответственность за их нарушение.",

    "Ответственность за безопасность и действия несовершеннолетних лиц на территории Парка несут их законные представители.",

    "Администрация Парка оставляет за собой право прекратить либо отказать в предоставлении оплаченных услуг в случае, если Райдер допускает нарушения настоящих Правил, допускает неуважительное отношение к другим посетителям или сотрудникам Парка.",

    "Администрация Парка не несет ответственности за утерянные и оставленные в Парке личные вещи.",

    "В посещении Парка может быть отказано лицам, поведение которых создает угрозу безопасности и мешает отдыху других посетителей.",

    "Посетители и сотрудники Парка обязаны быть взаимно вежливы, бережно относиться к сооружениям и оборудованию Парка, соблюдать чистоту, общественный порядок и требования настоящих Правил.",

    "В случае если услуга не может быть оказана по вине посетителя, возмещение стоимости услуги посетителю не производится (ч. 2 ст. 781 Гражданского кодекса Российской Федерации).",

    "Администрация Парка не несет ответственности за повреждения оборудования и иного имущества Райдеров, полученные в процессе катания или оставленного без присмотра.",

    "При повреждении арендованного спортивного инвентаря посетитель обязуется возместить стоимость ремонта/замены.",

    "Абонементы и подарочные сертификаты обмену, возврату не подлежат, действуют и могут быть использованы в течение Сезона катания региона (Йошкар-Ола) текущего года, когда они были приобретены.",

    "Праздники проводятся при условии бронирования катания",

    "В случае возникновения задымления или пожара необходимо немедленно сообщить об этом обслуживающему персоналу Парка.",

    "За последствия, возникшие в случае несоблюдения Вами настоящих Правил, Администрация Парка ответственности не несёт;",
  ];
  const warning = [
    "Перед катанием, каждый Райдер должен пройти инструктаж по технике безопасности.",

    "Катание разрешено только в спасательном жилете и шлеме.",

    "Сет начинается с момента оповещения инструктора о том, что можно спускаться на воду и оканчивается по прошествии 10 минут, о чем инструктор оповещают Райдера. Время катания всегда округляется кратно 10 минутам в большую сторону",

    "Час начинается с момента оповещения инструктора о том, что можно спускаться на воду и оканчивается по прошествии 60 минут, о чем инструктор оповещают Райдера. Время катания всегда округляется кратно 60 минутам в большую сторону. Час можно разделить с друзьями",

    "Для катания, в зависимости от уровня подготовки и навыков катания, Райдеру необходимо занять стартовую позицию, принять у оператора фал и крепко удерживая его руками подготовиться к старту.",

    "При опасном катании или катании с нарушением настоящих Правил на территории Парка, администрация вправе удалить Райдера с территории Парка. При несоблюдении правил катание для райдера будет прекращено без возврата денежных средств.",

    "При возникновении несчастных случаев свидетель или участник происшествия обязан сообщить об этом обслуживающему персоналу Парка незамедлительно.",

    "Перед прохождением под мостом необходимо забрать поданный фал и ехать на натянутом фале.",

    "В случае падения под мостом необходимо доплыть до доски, лечь на нее и грести вниз по течению.",

    "В случае падения на всех этапах трассы необходимо лечь на вейксерф, дождаться подачи фала, занять стартовую позицию, ожидать начала движения.",

    "В экстренных ситуациях необходимо неукоснительно выполнять требования Оператора/Капитана.",

    "КАТЕГОРИЧЕКИ запрещается наматывать фал на руку и другие части тела.",

    "После старта и стабилизации на волне, бросок Фала осуществляется строго за волну (через гребень волны).",

    "Возможны технические остановки канатной дороги. В этом случае необходимо оставаться в пределах трассы, и ожидать повторного запуска реверсивной дороги, если иное не указано обслуживающим персоналом",

    "В экстренных ситуациях необходимо неукоснительно выполнять требования обслуживающего персонала канатной дороги и администрации Парка",
  ];
  const deprecate = [
    "Посещать Парк в нетрезвом состоянии, а также в состоянии наркотического или токсического опьянения.",

    "Оставлять после себя мусор.",

    "Купание и ныряние на пирсе и зоне катания.",

    "Приносить в Парк оружие любого вида, огнеопасные, взрывчатые (в том числе пиротехнические изделия), легковоспламеняющиеся, отравляющие, токсичные, ядовитые и пахучие вещества, колющие и режущие предметы, стеклянную посуду и изделия, газовые баллоны.",

    "Запрещается курить (в том числе айкос) на территории пирса и помещениях Парка.",

    "Оставлять детей без присмотра.",

    "Посещать пирс детям без сопровождения взрослого.",

    "Выносить за пределы территории Парка прокатный инвентарь и любое оборудование, арендованное или взятое у Парка.",

    "Проявлять любые формы агрессии и неуважения к окружающим на территории Парка.",

    "На территории Парка запрещается производить торговую, рекламную, а также иную коммерческую деятельность без соответствующего разрешения администрации Парка.",
    "Запрещается находиться на территории пирса и в помещениях Парка с животными.",

    "Запрещается жевать жевательную резинку во время катания.",

    "Запрещается проносить продукты питания на территорию пирса и в зону катания.",

    "В случае нарушения запретов, установленных настоящими Правилами, администрация Парка вправе взимать штраф в размере 5000 (Пять тысяч) рублей за каждое выявленное нарушение. При отказе оплатить штраф, в посещении Парка будет отказано навсегда.",

    "Запрещается входить в любые технические и служебные помещения.",

    "Запрещено парковать транспортные средства непосредственно на территории Парка и перегораживать проезды. ",
  ];

  const { isAuth } = useContext(AuthContext);
  let navigate = useNavigate();

  return (
    <div className={clsx(pageStyle["center"], pageStyle["page-content"])}>
      <div className={pageStyle["content-wrapper"]}>
        <div className={styleRules.title}>
          <InfoIcon color='info' style={{ margin: "auto 0" }} />
          <Typography variant='h5' color='primary'>
            Общие положения
          </Typography>{" "}
        </div>
        <List>
          {info.map((item, index) => (
            <ListItem key={"1" + index}>
              <ListItemAvatar>
                {" "}
                <Avatar
                  variant='rounded'
                  sx={{ width: 32, height: 32, fontSize: "14px" }}
                >
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>

        <div className={styleRules.title}>
          <DangerousIcon color='error' />
          <Typography variant='h5' color='primary'>
            Запрещено
          </Typography>
        </div>
        <List>
          {deprecate.map((item, index) => (
            <ListItem key={"2" + index}>
              <ListItemAvatar>
                {" "}
                <Avatar
                  variant='rounded'
                  sx={{ width: 32, height: 32, fontSize: "14px" }}
                >
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>

        <div className={styleRules.title}>
          <WarningAmberIcon color='warning' />
          <Typography variant='h5' color='primary'>
            Правила катания
          </Typography>
        </div>
        <List>
          {warning.map((item, index) => (
            <ListItem key={"3" + index}>
              <ListItemAvatar>
                <Avatar
                  variant='rounded'
                  sx={{ width: 32, height: 32, fontSize: "14px" }}
                >
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
        {!isAuth && (
          <Button
            fullWidth
            variant='text'
            onClick={() => {
              navigate("/", { replace: true });
            }}
          >
            На главную
          </Button>
        )}
      </div>
    </div>
  );
};
