import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import page from "../pages/Page.module.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import CircularProgress from "@mui/material/CircularProgress";
import { userLevels } from "../helpers";

export const UserCard = ({
  showDialog,
  handleClose,
  user,
  signSave,
  confirmUser,
  currentUser,
  changeUserLvl,
  openDeleteDialog,
  changeCommentUser,
  switchAdmin,
  handleSpendFreeSets,
  resetPassword,
  isLoading,
}) => {
  const userConfirmed =
    user.smsCode && user.smsCode.length && user.smsCode === "success";
  const isGuest = !!user.uid;
  const freeSetsArray = new Array(user.toSpendSets).fill("bonus");
  const [spendFreeSets, setSpendFreeSets] = useState(user.toSpendSets ?? 0);

  const getTitle = () => {
    return `${user.role === "TRAINER" ? "Тренер" : "Пользователь"} ${
      user.name
    } ${user.secondName}`;
  };

  const getAge = () => {
    const today = new Date();
    const birthday = user.birthday.split(".");
    let age = today.getFullYear() - Number(birthday[2]);
    const todayMonth = today.getMonth() + 1;
    if (todayMonth < Number(birthday[1])) age--;
    if (
      todayMonth === Number(birthday[1]) &&
      today.getDate() < Number(birthday[0])
    )
      age--;

    return age;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>{getTitle()}</DialogTitle>

      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        {isLoading ? (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <DialogContentText sx={{ margin: "5px 0" }}>
              Номер телефона: {user.phone}
            </DialogContentText>
            <DialogContentText sx={{ margin: "5px 0" }}>
              Возраст: {getAge()}
            </DialogContentText>
            <DialogContentText sx={{ margin: "5px 0" }}>
              Дата рождения:{" "}
              {user.birthday &&
                user.birthday.split("T")[0].split("-").reverse().join(".")}
            </DialogContentText>
            <Grid container direction='column' sx={{ gap: "10px" }}>
              {!isGuest && (
                <Grid container alignItems='center' sx={{ gap: "10px" }}>
                  <Typography>Уровень:</Typography>
                  <Grid container alignItems='center' sx={{ gap: "10px" }}>
                    {userLevels.map((lvl) => {
                      return (
                        <Chip
                          key={lvl.type}
                          variant={
                            lvl.type === user.type ? "filled" : "outlined"
                          }
                          label={lvl.name}
                          color={lvl.color}
                          onClick={() => changeUserLvl(user.id, lvl.type)}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              )}
              <Grid container alignItems='center' sx={{ gap: "10px" }}>
                <Typography>
                  Техника безопасности:{" "}
                  <span
                    className={
                      user.isSigned ? page.textSuccess : page.textError
                    }
                  >
                    {user.isSigned ? "" : "не "}подписана
                  </span>
                </Typography>
                {!user.isSigned && (
                  <Chip
                    variant='filled'
                    label='Техника безопасности подписана'
                    color='warning'
                    onClick={() => signSave(isGuest ? user.uid : user.id)}
                  />
                )}
              </Grid>
              {!isGuest && (
                <Grid container alignItems='center' sx={{ gap: "10px" }}>
                  <Typography>
                    Статус:{" "}
                    <span
                      className={
                        userConfirmed ? page.textSuccess : page.textError
                      }
                    >
                      {userConfirmed ? "" : "не "}подтвержден
                    </span>
                  </Typography>

                  {!userConfirmed && (
                    <Chip
                      variant='filled'
                      label='Подтвердить'
                      color='success'
                      onClick={() => confirmUser(user.id, user.phone)}
                    />
                  )}
                </Grid>
              )}
              {!isGuest && (
                <>
                  <Grid container alignItems='center' sx={{ gap: "10px" }}>
                    <Typography>
                      Всего посещений:{user.countSets ?? 0}
                    </Typography>
                  </Grid>
                  <Grid container alignItems='center' sx={{ gap: "10px" }}>
                    <Typography>
                      Бесплатных сетов:{user.freeSets ?? 0}
                    </Typography>
                  </Grid>
                  <Grid container alignItems='center' sx={{ gap: "10px" }}>
                    <Typography>
                      Потрачено бесплатных сетов:{user.spendSets ?? 0}
                    </Typography>
                  </Grid>
                  <Grid container alignItems='center' sx={{ gap: "10px" }}>
                    <Typography>
                      Хочет потратить бесплатных сетов:{user.toSpendSets ?? 0}
                    </Typography>
                    {!!user.toSpendSets && (
                      <>
                        <Chip
                          variant='filled'
                          label='Потратить'
                          color='success'
                          onClick={() =>
                            handleSpendFreeSets(user.id, spendFreeSets)
                          }
                        />
                        <FormControl fullWidth sx={{ minWidth: 40 }}>
                          <Select
                            labelId='select-free-sets-label'
                            id='select-free-sets'
                            value={spendFreeSets}
                            label=''
                            onChange={(event) => {
                              setSpendFreeSets(event.target.value);
                            }}
                            sx={{ padding: "4px" }}
                          >
                            {freeSetsArray.map((item, index) => (
                              <MenuItem key={index} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Grid>
                </>
              )}

              <Typography>Примечание:</Typography>
              <TextareaAutosize
                style={{ fontSize: "18px", padding: 10 }}
                minRows={2}
                onBlur={(event) => {
                  if (event.target)
                    event.target.value = event.target.value.trim();
                  changeCommentUser({
                    newComment: event.target.value,
                    id: user.id,
                    uid: user.uid,
                  });
                }}
                defaultValue={user.comment}
              ></TextareaAutosize>
            </Grid>
          </>
        )}
      </DialogContent>
      {!isGuest && !isLoading && (
        <DialogActions sx={{ flexWrap: "wrap", gap: "8px" }}>
          {currentUser.role === "OWNER" && user.role !== "OWNER" && (
            <Button
              variant='contained'
              color='warning'
              onClick={() => resetPassword(user.id)}
              endIcon={<RemoveModeratorIcon />}
            >
              Сбросить пароль
            </Button>
          )}
          {currentUser.role === "OWNER" &&
            user.role !== "TRAINER" &&
            user.role !== "OWNER" && (
              <Button
                variant='contained'
                color='primary'
                onClick={() =>
                  switchAdmin(user.id, user.role === "USER" ? "ADMIN" : "USER")
                }
                endIcon={<AccountBoxIcon />}
              >
                Изменить роль на {user.role === "USER" ? "ADMIN" : "USER"}
              </Button>
            )}
          {user.id !== currentUser.id && user.role !== "OWNER" && (
            <Button
              variant='contained'
              color='error'
              onClick={() => openDeleteDialog(user)}
              endIcon={<DeleteForeverIcon />}
            >
              Удалить
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
