import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import dialogStyle from "./Dialog.module.css";

export const ConfigDialog = ({
  showDialog,
  handleClose,
  trainer,
  setTrainer,
  equips,
  setEquips,
  trainersList,
  setAcceptEquipClick,
  category,
  freeSets,
  sets,
  setSpendFreeSets,
  spendFreeSets,
}) => {
  const changeEquips = (event) => {
    setEquips({
      ...equips,
      [event.target.name]: {
        ...equips[event.target.name],
        checked: event.target.checked,
      },
    });
  };
  const freeSetsArray = new Array(freeSets).slice(0, sets.length).fill("bonus");

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>Выберите конфигурацию</DialogTitle>
      <DialogContent>
        {category !== "час" ? (
          <>
            {" "}
            {/* <div className={dialogStyle["trainer-select"]}>
              <FormControl fullWidth sx={{ minWidth: 120 }}>
                <InputLabel id='demo-simple-select-label'>
                  Выберите тренера
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={
                    trainersList.findIndex(({ name }) => name === trainer) !==
                    -1
                      ? trainer
                      : "Без тренера"
                  }
                  label='Выберите тренера'
                  onChange={(event) => {
                    setTrainer(event.target.value);
                  }}
                >
                  <MenuItem value={"Без тренера"}>Без тренера</MenuItem>
                  {trainersList.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            <DialogContentText sx={{ margin: "20px 0" }}>
              Выберите оборудование для аренды:
            </DialogContentText>
            <FormGroup>
              {Object.keys(equips).map((key) => {
                const hasIncludeCategory =
                  equips[key].include && equips[key].include.includes(category);
                if (equips[key].type === "equip")
                  return (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          checked={equips[key].checked || hasIncludeCategory}
                          onChange={changeEquips}
                          name={key}
                        />
                      }
                      label={
                        equips[key].name +
                        ` (${
                          hasIncludeCategory || !equips[key].price
                            ? "входит в стоимость"
                            : equips[key].price + " рублей"
                        })`
                      }
                    />
                  );
                return null;
              })}
            </FormGroup>
            {freeSetsArray && !!freeSetsArray.length && category === "сет" && (
              <div
                className={dialogStyle["trainer-select"]}
                style={{ margin: "20px 0" }}
              >
                <FormControl fullWidth sx={{ minWidth: 120 }}>
                  <InputLabel id="select-free-sets-label">
                    Использовать бесплатные сеты
                  </InputLabel>
                  <Select
                    labelId="select-free-sets-label"
                    id="select-free-sets"
                    value={spendFreeSets}
                    label="Использовать бесплатные сеты"
                    onChange={(event) => {
                      setSpendFreeSets(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    {freeSetsArray.map((item, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        ) : (
          <DialogContentText sx={{ margin: "20px 0" }}>
            Прокат снаряжения и дополнительные услуги оплачиваются отдельно в
            зависимости от количества человек
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отменить</Button>
        <Button onClick={() => setAcceptEquipClick(true)} variant="contained">
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
