import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useContext, useState } from "react";
import { AuthContext } from "../App";

export const AcceptReserveDialog = ({
  showDialog,
  handleClose,
  sets,
  hourSets,
  clientSets,
  trainer,
  equips,
  handleReservedClick,
  weekDay,
  category,
  spendFreeSets,
  isGuest,
  guest,
}) => {
  const { priceList, user } = useContext(AuthContext);
  const [isDisabledButtons, setIsDisabledButtons] = useState(false);
  const isNoTrainer = trainer === "Без тренера";
  const client = isGuest ? { ...guest, type: "STANDART" } : user;
  const choosedEquips = Object.keys(equips).filter(
    (key) =>
      equips[key].checked ||
      (equips[key].include && equips[key].include.includes(category))
  );

  const getTotalCost = () => {
    const choosedEquipsCost = choosedEquips.filter(
      (key) =>
        equips[key].checked ||
        (equips[key].include && !equips[key].include.includes(category))
    );

    const equipsCostBySet = choosedEquipsCost.reduce(
      (total, equipKey) => {
        if (!equips[equipKey].once) total += equips[equipKey].price;
        return total;
      },
      isNoTrainer ? 0 : equips.trainer.price
    );

    const equipsCostByOnce = choosedEquipsCost.reduce((total, equipKey) => {
      if (equips[equipKey].once) total += equips[equipKey].price;
      return total;
    }, 0);

    const isSetsOnly = !clientSets.length && !hourSets.length;
    const isHoliday = weekDay > 4;

    let totalCost = 0;
    if (isSetsOnly) {
      const discountSets = sets.filter((set) => {
        const hour = set.split(":")[0];
        return parseInt(hour, 10) < 11;
      });
      totalCost +=
        (sets.length - discountSets.length) *
          priceList[client.type][isHoliday ? "holiday" : "week"].set +
        discountSets.length *
          (isHoliday
            ? priceList[client.type].holiday.set
            : priceList[client.type].week.early);

      totalCost += equipsCostBySet * sets.length;
    } else {
      totalCost +=
        clientSets.length *
          priceList[client.type][isHoliday ? "holiday" : "week"].newClient +
        hourSets.length *
          priceList[client.type][isHoliday ? "holiday" : "week"].hour;

      totalCost +=
        equipsCostBySet * hourSets.length * 5 +
        equipsCostBySet * clientSets.length * 3;
    }

    totalCost += equipsCostByOnce;

    return totalCost;
  };

  const getTrainerName = () => {
    if (isNoTrainer) return trainer;
    return "тренера " + trainer;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>Подтверждение</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Вы хотите забронировать время{" "}
          {sets
            .sort((a, b) => {
              if (a.length < 5) a = "0" + a;
              if (b.length < 5) b = "0" + b;
              return a.localeCompare(b);
            })
            .join(", ")}{" "}
          на имя "{client.secondName} {client.name}" ?
        </DialogContentText>
        {category !== "час" && (
          <>
            <DialogContentText>Вы выбрали :</DialogContentText>
            <DialogContentText>
              {getTrainerName()} и дополнительное оборудование{" "}
              {choosedEquips
                .map((equipKey) => equips[equipKey].name)
                .join(", ")}
            </DialogContentText>
            <DialogContentText>
              Ориентировочная стоимость <strong>{getTotalCost()}</strong> рублей
            </DialogContentText>
            {!!spendFreeSets && (
              <DialogContentText>
                Бесплатные сеты будут учтены в стоимости при посещении
              </DialogContentText>
            )}
          </>
        )}
        <DialogContentText color="error">
          Приходить за 15 минут до начала сеанса катания!!!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isDisabledButtons}>
          Отменить
        </Button>
        <Button
          onClick={async () => {
            setIsDisabledButtons(true);
            await handleReservedClick().finaly(() =>
              setIsDisabledButtons(false)
            );
          }}
          disabled={isDisabledButtons}
          variant="contained"
        >
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
