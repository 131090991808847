import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export const CompleteUserDialog = ({ showDialog, userName, handleClose }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={showDialog}
      onClose={handleClose}
    >
      <DialogTitle>Новый тренер успешно создан</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ margin: "5px 0" }}>
          Логин: {userName}
        </DialogContentText>
        <DialogContentText sx={{ margin: "5px 0" }}>
          Пароль: {process.env.REACT_APP_PASS}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained'>
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
