import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useContext, useState } from "react";
import { AuthContext } from "../App";
import { fetchSaveProfile } from "../http";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import login from "./Login.module.css";
import Button from "@mui/material/Button";
import moment from "moment";
import pageStyle from "./Page.module.css";
import userStyle from "./User.module.css";
import { regExpDic } from "../helpers";

export const UserInfo = () => {
  const { user, setUser, config } = useContext(AuthContext);
  const [profile, setProfile] = useState(user);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inputsState, setInputsState] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  function validate(el) {
    const regExpName = el.name;
    if (!regExpDic[regExpName]) return true;
    return regExpDic[regExpName].test(el.value);
  }

  const handleSaveProfile = async (event) => {
    event.preventDefault();
    let saveProfile = profile;
    if (showPasswordFields && password.length && password === confirmPassword) {
      saveProfile.password = password;
    }

    const saveResult = await fetchSaveProfile(saveProfile);
    if (saveResult.message) {
      setErrorMessage(saveResult.message);
      setFetchError(true);
      return;
    }
    if (saveResult.result) {
      setSuccessMessage("Изменения сохранены");
      setPassword("");
      setConfirmPassword("");
      setUser(profile);
    }
  };

  return (
    <div className={pageStyle["page-content"]}>
      <h3>{user.name}</h3>

      <div className={userStyle["user-wrapper"]}>
        <form
          className={[login.form, userStyle["user-form"]].join(" ")}
          onSubmit={handleSaveProfile}
        >
          <TextField
            label='Телефон'
            name='phone'
            onChange={(event) => {
              const phoneString =
                user.role === "USER"
                  ? event.currentTarget.value
                      .trim()
                      .replace(/\(|\)|-|[^0-9+]/g, "")
                  : event.currentTarget.value;
              setProfile({ ...profile, phone: phoneString });
              fetchError && setFetchError(false);
            }}
            value={profile.phone}
            error={!profile.phone.length && !!errorMessage.length}
          />
          <Button
            variant='text'
            classes={{ root: login.button }}
            onClick={() => {
              setShowPasswordFields(!showPasswordFields);
            }}
          >
            {showPasswordFields ? "Не менять" : "Сменить"} пароль
          </Button>
          {showPasswordFields && (
            <>
              <TextField
                label='Пароль'
                name='password'
                type={inputsState.showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => {
                  setPassword(event.currentTarget.value);
                  fetchError && setFetchError(false);
                }}
                error={
                  (!password.length && !!errorMessage.length) ||
                  password !== confirmPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() =>
                          setInputsState({
                            ...inputsState,
                            showPassword: !inputsState.showPassword,
                          })
                        }
                      >
                        {inputsState.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label='Повторите пароль'
                name='confirmPassword'
                type={inputsState.showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event.currentTarget.value);
                  fetchError && setFetchError(false);
                }}
                error={!password.length && !!errorMessage.length}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle confirmPassword visibility'
                        onClick={() =>
                          setInputsState({
                            ...inputsState,
                            showConfirmPassword:
                              !inputsState.showConfirmPassword,
                          })
                        }
                      >
                        {inputsState.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
          <TextField
            label='Имя'
            name='name'
            defaultValue={user.name}
            onChange={(event) => {
              setProfile({ ...profile, name: event.currentTarget.value });
              fetchError && setFetchError(false);
            }}
            error={
              !!profile.name && !profile.name.length && !!errorMessage.length
            }
          />
          <TextField
            label='Фамилия'
            name='secondName'
            defaultValue={user.secondName}
            onChange={(event) => {
              setProfile({ ...profile, secondName: event.currentTarget.value });
              fetchError && setFetchError(false);
            }}
            error={
              !!profile.secondName &&
              !profile.secondName.length &&
              !!errorMessage.length
            }
          />
          <TextField
            label='email'
            name='email'
            defaultValue={user.email}
            onBlur={(event) => {
              if (event.currentTarget.value.trim())
                setIsValidEmail(validate(event.currentTarget));
              else setIsValidEmail(true);
            }}
            onChange={(event) => {
              setProfile({
                ...profile,
                email: event.currentTarget.value.trim(),
              });
              fetchError && setFetchError(false);
            }}
            error={!isValidEmail}
          />
          {!isValidEmail && (
            <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
              Email не соответствует виду 'example@email.ru'
            </Typography>
          )}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              label='Выберите дату'
              inputFormat='DD.MM.yyyy'
              value={moment(
                profile.birthday
                  ? profile.birthday.split(".").reverse().join("-")
                  : ""
              )}
              onChange={(newDate) => {
                setProfile({
                  ...profile,
                  birthday: newDate.format("DD.MM.yyyy"),
                });
              }}
              renderInput={(params) => <TextField {...params} size='medium' />}
            />
          </LocalizationProvider>

          {config &&
            config["Каждый десятый бесплатно"] &&
            config["Каждый десятый бесплатно"].status && (
              <Grid container wrap='nowrap' alignItems='center'>
                <CardGiftcardIcon />
                <Typography color='primary' className={pageStyle["error-text"]}>
                  У вас бесплатных сетов {user.freeSets ?? 0} шт.
                </Typography>
              </Grid>
            )}

          {!!errorMessage.length && (
            <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
              {errorMessage}
            </Typography>
          )}
          {!!successMessage.length && (
            <Typography color={"#1b5e20"} className={pageStyle["error-text"]}>
              {successMessage}
            </Typography>
          )}
          <Button
            variant='contained'
            type='submit'
            classes={{ root: login.button }}
          >
            Сохранить изменения
          </Button>
        </form>
      </div>
    </div>
  );
};
