import jwt_decode from "jwt-decode";

const app_dev_url = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_API_URL_STAGE
  : process.env.REACT_APP_API_URL_DEV;

const app_url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : app_dev_url;

const postFetch = async (url, body, isAuth) => {
  const response = await fetch(app_url + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      Authorization: isAuth ? `Bearer ${localStorage.getItem("token")}` : "",
    },
    body,
  });
  return await response.json();
};

const getFetch = async (url, isAuth) => {
  const response = await fetch(app_url + url, {
    headers: {
      Authorization: isAuth ? `Bearer ${localStorage.getItem("token")}` : "",
    },
  });
  return await response.json();
};

export const fetchReserve = async (params) => {
  return await postFetch("/rides/reserve", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchRservedSets = async (params) => {
  return await postFetch("/rides/sets", JSON.stringify(params), true);
};

export const fetchLogin = async (params) => {
  return await postFetch("/user/login", JSON.stringify(params)).catch(
    (error) => ({
      message: "Не удалось войти. Проверьте интернет соединение",
    })
  );
};

export const fetchRegistration = async (params) => {
  return await postFetch("/user/registration", JSON.stringify(params)).catch(
    ({ message }) => ({ message })
  );
};

export const fetchViewSets = async (params) => {
  return await postFetch("/rides/info", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchTrainersReserved = async (params) => {
  return await postFetch(
    "/trainers/reserved",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchAddTrainer = async (params) => {
  return await postFetch("/trainers/add", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchAllTrainers = async () => {
  return await getFetch("/trainers/info", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchDeleteTrainer = async (params) => {
  return await postFetch(
    "/trainers/delete",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const check = async () => {
  const { token } = await getFetch("/user/auth", true).catch((err) => {
    return { token: "" };
  });
  if (token) {
    localStorage.setItem("token", token);
    return jwt_decode(token);
  }
  return {};
};

export const fetchUserTikets = async () => {
  return await getFetch("/user/info", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchDeleteTikets = async (params) => {
  return await postFetch(
    "/rides/rejection",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchSaveProfile = async (params) => {
  return await postFetch("/user/update", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const updatePhoto = async (formData) => {
  const response = await fetch(app_url + "/trainers/photo", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });
  return await response.json().catch(({ message }) => ({ message }));
};

export const fetchClients = async (params) => {
  return await getFetch("/user/all", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchDeteteUser = async (params) => {
  return await postFetch("/user/delete", JSON.stringify(params), true).catch(
    ({ message }) => ({
      message,
    })
  );
};

export const fetchRidesApply = async (params) => {
  return await postFetch("/rides/apply", JSON.stringify(params), true).catch(
    ({ message }) => ({
      message,
    })
  );
};

export const fetchChangeTrainer = async (params) => {
  return await postFetch("/rides/trainer", JSON.stringify(params), true).catch(
    ({ message }) => ({
      message,
    })
  );
};

export const fetchEditDescription = async (params) => {
  return await postFetch("/trainers/edit", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchLogs = async () => {
  return await getFetch("/data/all", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchAddComment = async (params) => {
  return await postFetch(
    "/trainers/commets/add",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchTainersComments = async (params) => {
  return await postFetch(
    "/trainers/commets/all",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchMoreComments = async (params) => {
  return await postFetch(
    "/trainers/commets/more",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchDeleteComment = async (params) => {
  return await postFetch(
    "/trainers/commets/delete",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchAllPrice = async () => {
  return await getFetch("/price/all", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchBonusFree = async () => {
  return await getFetch("/bonus/free", true).catch(({ message }) => ({
    message,
  }));
};
export const fetchBonusSpend = async (params) => {
  return await postFetch("/bonus/spend", JSON.stringify(params), true).catch(
    ({ message }) => ({
      message,
    })
  );
};

export const fetchConfirmCode = async (params) => {
  return await postFetch("/user/confirm", JSON.stringify(params)).catch(
    ({ message }) => ({ message })
  );
};

export const fetchAgainCode = async (params) => {
  return await postFetch("/user/again", JSON.stringify(params)).catch(
    ({ message }) => ({ message })
  );
};

export const fetchResetCode = async (params) => {
  return await postFetch("/user/resetcode", JSON.stringify(params)).catch(
    ({ message }) => ({ message })
  );
};

export const fetchSignSave = async (params) => {
  return await postFetch("/user/signsave", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchSignSaveGuest = async (params) => {
  return await postFetch(
    "/guests/signsave",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchConfigReg = async () => {
  return await getFetch("/config/registration").catch(({ message }) => ({
    message,
  }));
};

export const fetchChangeStatusClient = async (params) => {
  return await postFetch(
    "/user/changetype",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchChangeStatusBonus = async (params) => {
  return await postFetch(
    "/config/setbonus",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchStatusBonus = async () => {
  return await getFetch("/config/bonus", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchChangeAdmin = async (params) => {
  return await postFetch(
    "/user/changeadmin",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchAllGuests = async () => {
  return await getFetch("/guests/all", true).catch(({ message }) => ({
    message,
  }));
};

export const fetchPageGuests = async (params) => {
  return await postFetch("/guests/page", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchFindGuests = async (params) => {
  return await postFetch("/guests/find", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchFindUsers = async (params) => {
  return await postFetch("/user/find", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};

export const fetchCommentUser = async (params) => {
  return await postFetch(
    "/user/changecomment",
    JSON.stringify(params),
    true
  ).catch(({ message }) => ({ message }));
};

export const fetchUserPaswordReset = async (params) => {
  return await postFetch("/user/resetpwd", JSON.stringify(params), true).catch(
    ({ message }) => ({ message })
  );
};
