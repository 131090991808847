import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { fetchAddTrainer, fetchAllTrainers, fetchDeleteTrainer } from "../http";
import Divider from "@mui/material/Divider";
import { DeleteUserDialog } from "../components/DeleteUserDialog";
import { CompleteUserDialog } from "../components/CompleteUserDialog";
import pageStyle from "./Page.module.css";
import trainers from "./Trainers.module.css";

export const Trainers = () => {
  const [trainersList, setTrainersList] = useState([]);
  const [trainerName, setTrainerName] = useState("");
  const [userName, setUserName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false);
  const [deleteTrainer, setDeleteTrainer] = useState({});

  useEffect(() => {
    fetchAllTrainers().then(({ trainers }) => {
      setTrainersList(trainers);
    });
  }, []);

  const handleAddTrainer = async () => {
    if (!trainerName.length) {
      setErrorMessage("Укакжите имя тренера");
      return;
    }
    const response = await fetchAddTrainer({
      name: trainerName.trim(),
    });

    if (response.message) {
      setErrorMessage(response.message);
      return;
    }

    if (response.result) {
      setUserName(response.newUser);
      setShowCompleteDialog(true);
      const { trainers } = await fetchAllTrainers();
      setTrainersList(trainers);
      setTrainerName("");
    }
  };

  const handleCloseDialog = () => {
    setShowCompleteDialog(false);
    setUserName("");
  };

  const handleClose = () => {
    setShowDialog(false);
    setDeleteTrainer({});
  };

  const handleDeleteTrainer = async () => {
    const resDelete = await fetchDeleteTrainer({ id: deleteTrainer.id });
    if (resDelete && resDelete.deleted) {
      const { trainers } = await fetchAllTrainers();
      setTrainersList(trainers);
    }
    handleClose();
  };

  return (
    <div className={pageStyle["page-content"]}>
      <div className={pageStyle["content-wrapper"]}>
        <List dense={false}>
          {trainersList.map((trainer, index) => (
            <ListItem
              key={trainer.id.toString() + index}
              secondaryAction={
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => {
                    setShowDialog(true);
                    setDeleteTrainer(trainer);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  alt={trainer.name}
                  src={
                    trainer.photo
                      ? process.env.REACT_APP_PHOTO_URL +
                        "/photo/" +
                        trainer.photo
                      : ""
                  }
                >
                  {trainer.photo ? "" : index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={trainer.name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <div className={trainers.control}>
          <TextField
            sx={{ flexGrow: 1 }}
            label='Новый тренер'
            name='trainer'
            onChange={(event) => {
              setTrainerName(event.currentTarget.value);
              setErrorMessage("");
            }}
            value={trainerName}
            error={!trainerName.length && !!errorMessage.length}
          />
          <Fab
            color='primary'
            aria-label='add'
            size='small'
            onClick={handleAddTrainer}
          >
            <AddIcon />
          </Fab>
        </div>
        <Typography color={"#d32f2f"} className={pageStyle["error-text"]}>
          {errorMessage}
        </Typography>
      </div>

      {showDialog && (
        <DeleteUserDialog
          showDialog={showDialog}
          userRole='TRAINER'
          userName={deleteTrainer.name}
          handleClose={handleClose}
          handleDeleteClick={handleDeleteTrainer}
        />
      )}
      {showCompleteDialog && (
        <CompleteUserDialog
          showDialog={showCompleteDialog}
          userName={userName}
          handleClose={handleCloseDialog}
        />
      )}
    </div>
  );
};
