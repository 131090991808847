import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rating from "@mui/material/Rating";
import { useState, useEffect, useContext } from "react";
import {
  fetchAllTrainers,
  fetchEditDescription,
  fetchAddComment,
  fetchTainersComments,
  updatePhoto,
  fetchMoreComments,
  fetchDeleteComment,
} from "../http";
import { AuthContext } from "../App";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import StarBorder from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import pageStyle from "./Page.module.css";
import trainers from "./Trainers.module.css";
import { clsx } from "../helpers";

export const OurTrainers = () => {
  const [trainersList, setTrainersList] = useState([]);
  const [isEdit, setIsEdit] = useState({});
  const { user } = useContext(AuthContext);
  const [isOpenCollapse, setIsOpenCollapse] = useState({});
  const [raitingsComment, setRaitingsComment] = useState({});
  const [errorState, setErrorState] = useState({});
  const [commentsByTrainer, setCommentsByTrainer] = useState({});
  const [changePhoto, setChangePhoto] = useState({});

  useEffect(() => {
    fetchAllTrainers().then(({ trainers }) => {
      setTrainersList(trainers);
    });
  }, []);

  useEffect(() => {
    const trainersIds = trainersList.map((trainer) => trainer.id);
    if (trainersIds.length)
      fetchTainersComments({ trainersIds }).then(({ result }) => {
        setCommentsByTrainer(result);
      });
  }, [trainersList]);

  const handleSaveDescription = async (trainerId) => {
    const descriptionInput = document.getElementById(
      "description_" + trainerId
    );
    const description = descriptionInput.value;

    const response = await fetchEditDescription({ id: trainerId, description });

    if (response.result) {
      const trainerIndex = trainersList.findIndex(
        (trainer) => trainer.id === trainerId
      );
      trainersList[trainerIndex].description = description;
      setTrainersList(trainersList);
      setIsEdit({ ...isEdit, [trainerId]: false });
    }
  };

  const handleSendCommit = async (trainerId) => {
    let errorArray = [];
    if (!raitingsComment[trainerId]) {
      errorArray.push("raiting");
      setErrorState({ ...errorState, [trainerId]: errorArray });
    }
    const commentInput = document.getElementById("comment_" + trainerId);
    const comment = commentInput.value.trim();
    if (!comment.length) {
      errorArray.push("comment");
      setErrorState({ ...errorState, [trainerId]: errorArray });
    }

    if (errorArray.length) return;

    const response = await fetchAddComment({
      trainerId,
      comment,
      date: moment().format("yyyy-MM-DD HH:mm:ss"),
      raiting: raitingsComment[trainerId],
      author: user.name + " " + user.secondName,
    });

    setCommentsByTrainer({ ...commentsByTrainer, ...response });
    setRaitingsComment({
      ...raitingsComment,
      [trainerId]: 0,
    });

    commentInput.value = "";
  };

  const handleSubmit = async (trainerId, trainerName) => {
    const currentInput = document.getElementById(`input_${trainerId}`);
    const newForm = new FormData();
    newForm.append("id", trainerId);
    newForm.append("name", trainerName);
    newForm.append("photo", currentInput.files[0]);

    const res = await updatePhoto(newForm);
    if (res.result) {
      const currentInput = document.getElementById(`input_${trainerId}`);
      setChangePhoto({
        ...changePhoto,
        [trainerId]: undefined,
      });
      currentInput.value = "";
      const trainerIndex = trainersList.findIndex(
        (trainer) => trainer.id === trainerId
      );
      const fileName = `${trainerName}Photo.jpg`
        .split(" ")
        .join("")
        .replace("#", "");
      trainersList[trainerIndex].photo = fileName;
      setTrainersList(trainersList);
    }
  };

  const getMoreComments = async (trainerId) => {
    const { result: comments } = await fetchMoreComments({
      trainerId,
      offsetComment: commentsByTrainer[trainerId].length,
    });

    setCommentsByTrainer({
      ...commentsByTrainer,
      [trainerId]: [...commentsByTrainer[trainerId], ...comments],
    });
  };

  const handleDeleteComment = async (commentId, trainerId, raiting) => {
    const { result } = await fetchDeleteComment({
      trainerId,
      commentId,
      raiting,
    });
    if (result) {
      setCommentsByTrainer({
        ...commentsByTrainer,
        [trainerId]: commentsByTrainer[trainerId].filter(
          (comment) => comment.id !== commentId
        ),
      });
    }
  };

  return (
    <div className={clsx(pageStyle["page-content"], trainers.content)}>
      {trainersList.map((item) => (
        <Card key={item.id} className={trainers.card}>
          <CardHeader
            title={item.name}
            titleTypographyProps={{ color: "primary", variant: "h4" }}
          />
          {item.photo && (
            <CardMedia
              component='img'
              height='350'
              image={process.env.REACT_APP_PHOTO_URL + "/photo/" + item.photo}
              alt={item.name}
            />
          )}
          <form
            id={`form_${item.id}`}
            style={{
              display: "none",
            }}
            className={trainers.control}
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit(event, item.id);
            }}
          >
            <input
              id={`input_${item.id}`}
              label='photo'
              name='photo'
              type='file'
              accept='.jpg, .jpeg, .png'
              onChange={(event) => {
                setChangePhoto({
                  ...changePhoto,
                  [item.id]: event.target.files[0].name,
                });
              }}
            />
          </form>
          {user.role === "OWNER" &&
            (!changePhoto[item.id] ? (
              <div className={clsx(trainers.button, trainers["avatar-change"])}>
                <Chip
                  variant={"outlined"}
                  color={"primary"}
                  label='Сменить аватар'
                  onClick={() => {
                    const currentInput = document.getElementById(
                      `input_${item.id}`
                    );
                    currentInput.click();
                  }}
                />
              </div>
            ) : (
              <div
                className={clsx(trainers.button, trainers["avatar-control"])}
              >
                <Typography className={trainers["avatar-title"]}>
                  {changePhoto[item.id]}
                </Typography>
                <Chip
                  variant={"filled"}
                  color={"success"}
                  label='Сохранить'
                  onClick={async () => {
                    await handleSubmit(item.id, item.name);
                  }}
                />
                <Chip
                  sx={{ marginLeft: "8px" }}
                  variant={"outlined"}
                  color={"error"}
                  label='Отмена'
                  onClick={() => {
                    const currentInput = document.getElementById(
                      `input_${item.id}`
                    );
                    setChangePhoto({
                      ...changePhoto,
                      [item.id]: undefined,
                    });
                    currentInput.value = "";
                  }}
                />
              </div>
            ))}
          {user.role === "OWNER" && !isEdit[item.id] && (
            <Button
              fullWidth
              variant='outlined'
              className={trainers.button}
              onClick={() => {
                setIsEdit({ ...isEdit, [item.id]: true });
              }}
            >
              Редактировать
            </Button>
          )}
          {isEdit[item.id] ? (
            <>
              <TextField
                id={`description_${item.id}`}
                className={trainers.button}
                defaultValue={item.description}
                multiline
                minRows={3}
                fullWidth
                placeholder='Описание'
                autoFocus
              />
              <Button
                fullWidth
                color='success'
                variant='contained'
                className={trainers.button}
                onClick={() => {
                  handleSaveDescription(item.id);
                }}
              >
                Сохранить
              </Button>
            </>
          ) : (
            <CardContent>{item.description}</CardContent>
          )}
          <CardActions
            disableSpacing
            onClick={() => {
              setIsOpenCollapse({
                ...isOpenCollapse,
                [item.id]: !isOpenCollapse[item.id],
              });
            }}
            sx={{ cursor: "pointer", gap: "10px" }}
          >
            <Rating
              name={item.name}
              precision={0.25}
              value={item.raiting || 0}
              readOnly
              sx={{ marginRight: "auto" }}
            />
            <Typography sx={{ color: "rgba(0,0,0,0.57)" }}>Отзывы</Typography>

            <ExpandMoreIcon
              style={{
                fill: "rgba(0,0,0,0.57)",
                transform: !isOpenCollapse[item.id]
                  ? "rotate(0deg)"
                  : "rotate(180deg)",
              }}
            />
          </CardActions>
          <Collapse in={isOpenCollapse[item.id]} timeout='auto' unmountOnExit>
            {isOpenCollapse[item.id] &&
              commentsByTrainer[item.id] &&
              commentsByTrainer[item.id].map((post) => {
                const dateArray = post.date.split(" ");
                const date =
                  dateArray[0].split("-").reverse().join(".") +
                  " " +
                  dateArray[1];
                return (
                  <CardContent
                    className={trainers["comment-container"]}
                    key={post.id}
                  >
                    <div className={trainers["comment-header"]}>
                      <Avatar>{post.author[0]}</Avatar>{" "}
                      <div>
                        <Typography>{post.author}</Typography>
                        <Typography variant='caption'>{date}</Typography>
                      </div>{" "}
                      <Rating
                        name={post.author}
                        value={post.raiting}
                        readOnly
                        sx={{ marginLeft: "auto" }}
                      />
                      {user.role === "OWNER" && (
                        <IconButton
                          className={trainers["comment-trash"]}
                          aria-label='delete'
                          onClick={() =>
                            handleDeleteComment(post.id, item.id, post.raiting)
                          }
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      )}
                    </div>

                    <Typography>{post.comment}</Typography>
                  </CardContent>
                );
              })}
            <CardContent>
              <Button
                id={"btn_more_comments_" + item.id}
                fullWidth
                color='primary'
                variant='outlined'
                onClick={() => getMoreComments(item.id)}
              >
                Показать ещё
              </Button>

              <TextField
                id={`comment_${item.id}`}
                sx={{ marginTop: "8px" }}
                multiline
                minRows={2}
                fullWidth
                placeholder='Ваш коментарий'
                error={
                  errorState[item.id] && errorState[item.id].includes("comment")
                }
                onChange={() => {
                  if (
                    errorState[item.id] &&
                    errorState[item.id].includes("comment")
                  )
                    setErrorState({
                      ...errorState,
                      [item.id]: errorState[item.id].filter(
                        (error) => error !== "comment"
                      ),
                    });
                }}
              />
              <Rating
                name={`comment_${item.id}`}
                sx={{ padding: "12px 0" }}
                value={raitingsComment[item.id] || 0}
                onChange={(event, newValue) => {
                  setRaitingsComment({
                    ...raitingsComment,
                    [item.id]: newValue,
                  });
                  if (
                    errorState[item.id] &&
                    errorState[item.id].includes("raiting")
                  )
                    setErrorState({
                      ...errorState,
                      [item.id]: errorState[item.id].filter(
                        (error) => error !== "raiting"
                      ),
                    });
                }}
                emptyIcon={
                  <StarBorder
                    fontSize='inherit'
                    color={
                      errorState[item.id] &&
                      errorState[item.id].includes("raiting")
                        ? "error"
                        : ""
                    }
                  />
                }
              />
              <Button
                fullWidth
                color='success'
                variant='contained'
                className={trainers.button}
                onClick={() => handleSendCommit(item.id)}
              >
                Отправить
              </Button>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  );
};
